import React from "react";
import { Button, CircularProgress, Link as MUILink, Grid, Typography, Container } from "@material-ui/core";
import { Link } from "react-router-dom";
import { TextField } from "formik-material-ui";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import Fade from "react-reveal/Fade";
import { connect } from "react-redux";
import { createUser1 } from "../../actions/userActions";
import { sendCaptcha } from "../../actions/userActions";
import { APIRequest } from "../../utils";

const StyledButton = withStyles((theme) => ({
  contained: {
    fontFamily: "avenir-next-lt-pro, sans-serif",
    color: "#ffffff",
    backgroundColor: "#0061d5",
    borderRadius: "4px",
    display: "inline-block",
    border: "none",
    textDecoration: "none",
    fontSize: "1rem",
    transition: "background 100ms ease,border-color 100ms ease",
    lineHeight: "1",
    minHeight: "3em",
    "&:hover": {
      opacity: "0.5",
      backgroundColor: "#0979ff",
    },
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  formTitle: {
    fontSize: "1.75rem", // make it consistent as the packages page
    lineHeight: "2.25rem", // make it consistent as the packages page
    marginBottom: theme.spacing(2),
    letterSpacing: 0, // override a negative setting in MuiTypography
    fontFamily: "itc-avant-garde-gothic-bold",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    root: {
      "&hover": {
        opacity: 0.3,
        backgroundColor: "#ef3e3d",
      },
    },
  },
  errorLink: {
    "&:hover": {
      color: "red",
      cursor: "context-menu",
    },
    fontWeight: 600,
    textDecoration: "none",
    padding: "0 8px",
    textAlign: "right",
  },
  button: {
    root: {
      "&hover": {
        opacity: 0.3,
        backgroundColor: "#ef3e3d",
      },
    },
  },
}));

function SignupFormHeader() {
  const classes = useStyles();
  return (
    <Typography component="h1" variant="h4" className={classes.formTitle}>
      Create Your Account
    </Typography>
  );
}

const validationSchema = yup.object().shape({
  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
  email: yup.string().required("Required").email("Invalid Email"),
  restaurantName: yup.string().required("Required"),
});

async function validateEmail(value) {
  let error;
  const bodyContent = {
    passport: value,
    passportType: "email",
  };
  const SERVER_URL = process.env.REACT_APP_SERVER_URL ?? "http://localhost:4000";
  const res = await APIRequest({
    url: `${SERVER_URL}/proxyAuth/passport/hasRegister`,
    method: "POST",
    body: bodyContent,
  });
  const data = await res.json();
  if (data.result.hasRegister) {
    error = "Email already exists!";
  }
  return error;
}

function CreateAccountForm(props) {
  const classes = useStyles();
  let { from } = props.location.state || { from: { pathname: "/" } };
  sessionStorage.setItem("from",JSON.stringify({from}));
  return (
      <Container maxWidth="xs" className={classes.paper}>
        <SignupFormHeader className={classes.formTitle} />

        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            restaurantName: "",
          }}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={async (values, { setSubmitting }) => {
            setTimeout(() => {
              setSubmitting(false);
            }, 3000);
            props.createUser1(values);
            await props.sendCaptcha();
            props.history.push({
              pathname: "/emailVerify",
              state: { from },
            });
          }}
          validationSchema={validationSchema}
        >
          {({ submitForm, isSubmitting, touched, errors }) => (
            <Form className={classes.form}>
              <Fade bottom cascade>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Field component={TextField} variant="outlined" name="firstName" type="text" label="First Name" autoFocus fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field component={TextField} variant="outlined" name="lastName" type="text" label="Last Name" fullWidth />
                  </Grid>
                  <Grid item xs={12}>
                    <Field component={TextField} variant="outlined" type="email" label="Email" name="email" fullWidth validate={validateEmail} />
                  </Grid>
                  <Grid item xs={12}>
                    <Field component={TextField} variant="outlined" type="text" label="Restaurant Name" name="restaurantName" fullWidth />
                  </Grid>

                  <Grid item xs={12}>
                    <StyledButton variant="contained" color="primary" disabled={isSubmitting} onClick={submitForm} fullWidth className={classes.submit}>
                      {isSubmitting && <CircularProgress size={16} style={{ marginRight: 8 }} />}
                      Submit
                    </StyledButton>
                  </Grid>
                  <div style={{ padding: "0 8px", textAlign: "right", width: "100%" }}>
                    <MUILink
                      component={Link}
                      to={{
                        pathname: "/login",
                        state: { from },
                      }}
                      variant="body2"
                      className={classes.errorLink}
                    >
                      {"Already have an account? Log In!"}
                    </MUILink>
                  </div>
                </Grid>
              </Fade>
            </Form>
          )}
        </Formik>
      </Container>

  );
}

export default connect((state) => ({}), {
  createUser1,
  sendCaptcha,
})(CreateAccountForm);
