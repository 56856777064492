import { SELECT_PLAN, FORMAT_PLAN, CLEAR_CURRENT_SELECTEDPLAN, EDIT_PLAN, SELECT_MULTIPLE_PLANS } from "../types";
var shortid = require("shortid");

// selectedPlan will contain all the fileds come with plan,
// together with 7 generated fields
// The data will not be sent to chekcout API
// Mainly used for front-end viwer triggered.
// e.g.: cardConnect Checkbox consent
export const selectPlan = (plan, selectedAddons, selectedSetups, totalPrice_monthly, totalPrice_annually, totalPrice_deposit, totalPrice_implementation) => (
  dispatch,
  getState
) => {
  let planList = getState().plan.selectedPlans;
  planList = planList ?? [];
  const newPlan = {
    ...plan,
    id: shortid.generate(),
    selectedAddons,
    selectedSetups,
    totalPrice_monthly,
    totalPrice_annually,
    totalPrice_deposit,
    totalPrice_implementation,
  };
  // const updatedPlanList = [...planList, newPlan];
  planList.push(newPlan);
  dispatch({
    type: SELECT_PLAN,
    payload: {
      currentPlan: newPlan,
      selectedPlans: planList,
    },
  });
  // localStorage.setItem("selectedPlan", JSON.stringify(selectedPlan));
};

export const select_multiple_plans = (count, plan) => (dispatch, getState) => {
  let planList = getState().plan.selectedPlans;
  planList = planList ?? [];

  for (let i = 0; i < count; i++) {
    planList = [...planList, plan];
  }

  dispatch({
    type: SELECT_MULTIPLE_PLANS,
    payload: { selectedPlans: planList },
  });
};

// export const selectAddons = (value) => (dispatch,getState) => {
//     const selectedPlan = getState().plan.selectedPlan;
//     const selectedAddons = selectedPlan.selectedAddons;
//     const updatedAddons = selectedAddons.map(x => x.key).includes(value) ? selectedAddons.filter(c => c.key !== value) : [...selectedAddons, ...selectedPlan.addons.filter(x => x.key === value)];
//     const updatedSelectedPlan = {...selectedPlan, selectedAddons: updatedAddons};
//     dispatch({
//       type: SELECT_ADDONS,
//       payload: { selectedPlan: updatedSelectedPlan },
//     });
//   };

//   export const selectInstallation = (value) => (dispatch,getState) => {
//     const selectedPlan = getState().plan.selectedPlan;
//     const selectedInstallation = selectedPlan.selectedInstallations;
//     const updatedInstallation = selectedInstallation.includes(value) ? selectedInstallation.filter(c => c !== value) : [...selectedInstallation, value];
//     const updatedSelectedPlan = {...selectedPlan, selectedInstallations: updatedInstallation};
//     dispatch({
//       type: SELECT_INSTALLATION,
//       payload: { selectedPlan: updatedSelectedPlan },
//     });
//   };

export const formatPlan = () => (dispatch, getState) => {
  const selectedPlan = getState().plan.selectedPlan;
  const monthlyCharge = selectedPlan.billedMonthly ?? 0; //basic in the plan
  const annuallyCharge = selectedPlan.billedYearly ?? 0; //basic in the plan
  const onetimeCharge = selectedPlan.implementation ?? 0; //online install

  const MCharge = selectedPlan.selectedAddons.reduce((a, c) => a + c.billedMonthly ?? 0, monthlyCharge);
  const ACharge = selectedPlan.selectedAddons.reduce((a, c) => a + c.billedYearly ?? 0, annuallyCharge);

  // one time fee comes from 1)Plan.implementation 2)Installation fee of online 3)Optional Addons deposit+imp
  const OTCharge =
    selectedPlan.selectedAddons.length > 0
      ? selectedPlan.selectedInstallations.length > 1
        ? selectedPlan.selectedAddons
            .filter((x) => selectedPlan.selectedInstallations.includes(x.key))
            .reduce((a, c) => a + c.implementation ?? 0 + c.deposit ?? 0, onetimeCharge)
        : selectedPlan.selectedAddons.reduce((a, c) => a + c.deposit ?? 0, onetimeCharge)
      : onetimeCharge;

  const updatedSelectedPlan = {
    ...selectedPlan,
    monthlyCharge: MCharge,
    onetimeCharge: OTCharge,
    annuallyCharge: ACharge,
  };

  dispatch({
    type: FORMAT_PLAN,
    payload: { selectedPlan: updatedSelectedPlan },
  });
  // localStorage.setItem("selectedPlan", JSON.stringify(updatedSelectedPlan));
};

export const clearCurrentSelectedPlan = () => (dispatch) => {
  dispatch({ type: CLEAR_CURRENT_SELECTEDPLAN });
  // localStorage.setItem("selectedPlan", JSON.stringify(null));
};

export const editPlan = (plan) => (dispatch, getState) => {
  const selectedPlan = getState().order.orderItems.filter((x) => x.id === plan.id);
  dispatch({
    type: EDIT_PLAN,
    payload: { selectedPlan: selectedPlan[0] },
  });
};
