import React from "react";
import { Button, Box, Link, Typography, Container, Checkbox, FormControlLabel, FormGroup, CircularProgress } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import Fade from "react-reveal/Fade";
import { connect } from "react-redux";
import { verifyCaptcha, sendCaptcha, createUser2, register } from "../../actions/userActions";
import { APIRequest } from "../../utils";
import { useSnackbar } from "notistack";

const StyledButton = withStyles((theme) => ({
  contained: {
    fontFamily: "avenir-next-lt-pro, sans-serif",
    color: "#ffffff",
    backgroundColor: "#0061d5",
    borderRadius: "4px",
    display: "inline-block",
    border: "none",
    textDecoration: "none",
    fontSize: "1rem",
    transition: "background 100ms ease,border-color 100ms ease",
    lineHeight: "1",
    minHeight: "3em",
    "&:hover": {
      opacity: "0.5",
      backgroundColor: "#0979ff",
    },
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  formTitle: {
    justify: "center",
    marginBottom: theme.spacing(2),
  },
  verification: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  link: {
    "&:hover": {
      color: "red",
      cursor: "context-menu",
    },
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  Checkbox: {
    width: 30,
    height: 30,
  },
}));

function FormTitle() {
  return (
    <Typography component="h1" variant="h4">
      Verify Your Email
    </Typography>
  );
}

function EmailVerifyForm(props) {
  const classes = useStyles();
  let { from } = props.location.state;
  const quoteID = sessionStorage.getItem("quoteID");
  const showCardConnectCheckbox = quoteID
    ? false
    : props.selectedPlans.length > 0
    ? props.selectedPlans.filter((x) => x.cardConnectConsent === true).length > 0
    : true;
  const { enqueueSnackbar } = useSnackbar(); //, closeSnackbar

  const [states, setStates] = React.useState({
    checkedA: false,
    checkedB: false,
    passportToken: null,
  });

  const handleCheckBox = (event) => {
    setStates({ ...states, [event.target.name]: event.target.checked });
  };

  function resendLink() {
    props.sendCaptcha();
    //snackbar settings
    const message = "Verification code has been resent to your email.";
    enqueueSnackbar(message, {
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
      variant: "success",
      autoHideDuration: 1000,
    });
  }

  async function validateVerificationCode(value) {
    let error;
    const bodyContent = {
      passport: props.currentUser.email,
      passportType: "email",
      captcha: value,
      captchaType: "register",
    };
    const SERVER_URL = process.env.REACT_APP_SERVER_URL ?? "http://localhost:4000";
    const res = await APIRequest({
      url: `${SERVER_URL}/proxyAuth/captcha/verifyCaptcha`,
      method: "POST",
      body: bodyContent,
    });
    const data = await res.json();
    if (data.error) {
      switch (data.error.code) {
        case "40006":
          return (error = "Verification code is wrong");
      }
    } else if (data.result) {
      setStates({
        ...states,
        passportToken: data.result.passportToken,
      });
    }
    return error;
  }

  const validationSchema = yup.object().shape({
    verification: yup.string().required("Required").min(6, "Should be 6 characters"),
    password: yup.string().required("Required").min(8, "Should be greater than 8 characters").max(15, "Should be smaller than 15 characters"),
    retypePassword: yup
      .string()
      .required("Required")
      .test("passwords-match", "Passwords must match", function (value) {
        return this.parent.password === value;
      }),
  });

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <FormTitle className={classes.formTitle} />
        <Formik
          initialValues={{
            verification: "",
            password: "",
            retypePassword: "",
          }}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={async (values, { setSubmitting }) => {
            setTimeout(() => {
              setSubmitting(false);
            }, 5000);
            props.createUser2(values.password);
            await props.register(states.passportToken);
            props.history.replace(from);
            //snackbar settings
            const message = "Successfully registered.";
            enqueueSnackbar(message, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "center",
              },
              variant: "success",
              autoHideDuration: 1000,
            });
          }}
          validationSchema={validationSchema}
        >
          {({ submitForm, isSubmitting, touched, errors }) => (
            <Form className={classes.form}>
              <Fade bottom cascade>
                <div>
                  <Box margin={2}>
                    <Typography variant="subtitle1" dispay="block" align="center">
                      We have sent a verification code to <strong>{props.currentUser.email}</strong>. Please check your email.
                    </Typography>
                  </Box>
                  <Box margin={2}>
                    <Field
                      component={TextField}
                      variant="outlined"
                      name="verification"
                      type="text"
                      label="Verification Code"
                      size="small"
                      autoFocus
                      fullWidth
                      validate={validateVerificationCode}
                      // onBlur={validateVerificationCode}
                    />

                    <Typography align="right" marginRight={2} variant="caption" display="block">
                      Didn't receive your code?{" "}
                      <Link className={classes.link} color="primary" underline="always" onClick={resendLink}>
                        Resend
                      </Link>
                    </Typography>
                  </Box>
                  {/* <ResendVerif values={values} /> */}

                  {/* {isSubmitting && <LinearProgress />} */}

                  <Box margin={2}>
                    <Field component={TextField} variant="outlined" name="password" type="password" label="Password" size="small" fullWidth />
                  </Box>

                  <Box margin={2}>
                    <Field component={TextField} variant="outlined" name="retypePassword" type="password" label="Confirm Password" size="small" fullWidth />
                  </Box>

                  <Box margin={2}>
                    <FormGroup column>
                      <FormControlLabel
                        style={{ display: "table" }}
                        control={
                          <div style={{ display: "table-cell" }}>
                            <Checkbox size="small" checked={states.checkedA} onChange={handleCheckBox} name="checkedA" color="primary" />
                          </div>
                        }
                        label={
                          <Typography variant="caption" component="p">
                            I agree to eShine's{" "}
                            <Link className={classes.link} underline="always" color="primary" href="https://www.trytabletop.com/terms" target="_blank">
                              Terms Of Service
                            </Link>{" "}
                            and{" "}
                            <Link href="https://www.trytabletop.com/privacy" className={classes.link} underline="always" color="primary" target="_blank">
                              Privacy Policy
                            </Link>
                          </Typography>
                        }
                        labelPlacement="end"
                      />

                      {showCardConnectCheckbox && (
                        <FormControlLabel
                          style={{ display: "table" }}
                          control={
                            <div style={{ display: "table-cell" }}>
                              <Checkbox size="small" onChange={handleCheckBox} name="checkedB" color="primary" />
                            </div>
                          }
                          label={
                            <Typography component="p" variant="caption">
                              I agree to use{" "}
                              <Link className={classes.link} underline="always" color="primary" href="https://cardconnect.com/" target="_blank">
                                CardConnect
                              </Link>{" "}
                              as my credit card processor. I understand that I will not be able to use eShine's service if CardConnect does not approve my
                              application.
                            </Typography>
                          }
                          labelPlacement="end"
                        />
                      )}
                    </FormGroup>
                  </Box>

                  <Box margin={2}>
                    <StyledButton
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting || (showCardConnectCheckbox ? !(states.checkedA && states.checkedB) : !states.checkedA)} //localStorage.getItem("quoteID")
                      onClick={submitForm}
                      fullWidth
                      className={classes.submit}
                    >
                      {isSubmitting && <CircularProgress size={16} style={{ marginRight: 8 }} />}
                      Create Your Account
                    </StyledButton>
                    {/* </RouterLink> */}
                  </Box>
                </div>
              </Fade>
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  );
}

export default connect(
  (state) => ({
    selectedPlans: state.plan.selectedPlans,
    currentUser: state.user.currentUser,
    passportToken: state.user.passportToken,
    accessToken: state.user.accessToken,
  }),
  { createUser2, verifyCaptcha, sendCaptcha, register }
)(EmailVerifyForm);
