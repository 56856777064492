import React from "react";
import "./header.css";
import { withStyles } from "@material-ui/core/styles";
import { Link as RouterLink } from "react-router-dom";
import tabletopLOGO from "../../image/tabletopLOGO.png";
import { connect } from "react-redux";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import Badge from "@material-ui/core/Badge";
import { logout } from "../../actions/userActions";
import { useHistory, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";

const CustomizedBadge = withStyles({
  badge: {
    color: "#fff",
    backgroundColor: "#3FB87F",
    fontSize: "1em",
  },
})(Badge);

const Header = (props) => {
  const history = useHistory();
  const location = useLocation();
  const curPath = location.pathname;
  const [navIsOpen, setNavIsOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar(); //, closeSnackbar

  const toggleNavOpen = () => {
    setNavIsOpen(!navIsOpen);
  };

  return (
    <header className="header">
      <div className="header-announcement-bar-wrapper">
        <div className=" header-inner">
          <div className="header-title-nav-wrapper">
            <div className="header-title">
              <div className="header-title-logo">
                <a href="https://www.trytabletop.com">
                  <img src={tabletopLOGO} alt="TableTop" />
                </a>
              </div>
            </div>
            <div className={navIsOpen ? "header-nav header-nav--open" : "header-nav"}>
              <a class="btn talk_to_expert_btn" 
                target="_blank" 
                underline="never" 
                color="white" 
                href="https://www.trytabletop.com/talk-to-an-expert"
              >
                Talk To An Expert
                </a>
              {props.isLogin ? (
                <RouterLink
                  className="btn logout_btn"
                  onClick={() => {
                    if (navIsOpen) {
                      setNavIsOpen(false);
                    }
                    props.logout();
                    history.replace("/");
                    //snackbar settings
                    const message = "Successfully logged out.";
                    enqueueSnackbar(message, {
                      anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                      },
                      variant: "success",
                      autoHideDuration: 1000,
                    });
                  }}
                >
                  Logout
                </RouterLink>
              ) : (
                <>
                  <RouterLink
                    className="btn signup_btn"
                    onClick={() => {
                      if (navIsOpen) {
                        setNavIsOpen(false);
                      }
                    }}
                    to={{
                      pathname: "/signup",
                      state: JSON.parse(sessionStorage.getItem("from")),
                    }}
                  >
                    Sign Up
                  </RouterLink>
                  <RouterLink
                    className="btn login_btn"
                    onClick={() => {
                      if (navIsOpen) {
                        setNavIsOpen(false);
                      }
                    }}
                    to={{
                      pathname: "/login",
                      state: JSON.parse(sessionStorage.getItem("from")),
                    }}
                  >
                    Log In
                  </RouterLink>
                </>
              )}
              {props.selectedLocationMode === "multiple" && curPath === "/" && (
                <RouterLink underline="never" color="black" to={"/orderSummary"}>
                  <CustomizedBadge badgeContent={props.cartCount}>
                    <AddShoppingCartIcon fontSize="large" />
                  </CustomizedBadge>
                </RouterLink>
              )}
            </div>

            {/* <!-- Burger --> */}
            <div className="header-burger" onClick={() => toggleNavOpen()}>
              <button className="header-burger-btn burger">
                <div className="burger-box">
                  <div className="burger-inner header-menu-icon-doubleLineHamburger">
                    <div className="top-bun"></div>
                    <div className="patty"></div>
                    <div className="bottom-bun"></div>
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default connect(
  (state) => ({
    isLogin: state.user.isLogin,
    selectedLocationMode: state.order.selectedLocationMode,
    cartCount: state.order.itemNumber,
  }),
  {
    logout,
  }
)(Header);
