import React from "react";
import { Typography, Link } from "@material-ui/core";

function Copyright() {
  return (
    <footer>
      <Typography variant="body2" color="textSecondary" align="center">
        {"Copyright © "}
        <Link color="inherit" href="https://www.trytabletop.com/">
          eShine Inc.
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    </footer>
  );
}

export default Copyright;
