export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const ADD_TO_ORDER = "ADD_TO_ORDER";
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";

export const REMOVE_FROM_ORDER = "REMOVE_FROM_ORDER";
export const CREATE_ORDER = "CREATE_ORDER";
export const CLEAR_ORDER = "CLEAR_ORDER";
export const FETCH_ORDERS = "FETCH_ORDERS";
export const CLEAN_ORDER = "CLEAN_ORDER";
export const UNDELETE_ITEM = "UNDELETE_ITEM";

export const CREATE_USERS1 = "CREATE_USERS1";
export const CREATE_USERS2 = "CREATE_USERS2";

export const SELECT_PLAN = "SELECT_PLAN";
export const SELECT_ADDONS = "SELECT_ADDONS";
export const SELECT_INSTALLATION = "SELECT_INSTALLATION";
export const SELECT_LOCATION_MODE = "SELECT_LOCATION_MODE";

export const SELECT_PAYMODE = "SELECT_PAYMODE";
export const FORMAT_PLAN = "FORMAT_PLAN";
export const CLEAR_CURRENT_SELECTEDPLAN = "CLEAR_CURRENT_SELECTEDPLAN";

export const COPY_LOCATION = "COPY_LOCATION";
export const EDIT_PLAN = "EDIT_PLAN";
export const FINALIZEORDER = "FINALIZEORDER";
export const USEREMVDATA = "USEREMVDATA";
export const USERPAYMENTINFO = "USERPAYMENTINFO";

export const LOGIN = "LOGIN";
export const SIGNUP = "SIGNUP";
export const LOGOUT = "LOGOUT";
export const GET_USER_INFO = "GET_USER_INFO";

export const SEND_CAPTCHA = "SEND_CAPTCHA";
export const VERIFY_CAPTCHA = "VERIFY_CAPTCHA";
export const REGISTER = "REGISTER";
export const FAILED_LOGIN = "FAILED_LOGIN";

export const FETCH_QUOTE_ID = "FETCH_QUOTE_ID";

export const ADD_SINGLE_PLAN = " ADD_SINGLE_PLAN";
export const ADD_MULTIPLE_PLANS = " ADD_MULTIPLE_PLANS";
export const SELECT_MULTIPLE_PLANS = " SELECT_MULTIPLE_PLANS";
