export const POST_PAYMENT_INFO = `
mutation checkoutMutation (
  $brand: String,
  $payment: PaymentInfo,
  $order: OrderInfo,
  $quote: String,
  $accessToken: String,
  $refreshToken: String
) { 
  checkout(
    brand: $brand,
    payment: $payment,
    order: $order,
    quote: $quote,
    accessToken: $accessToken,
    refreshToken: $refreshToken
  ) {
    status
  }
}`;
