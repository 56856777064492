import { React, useState } from "react";
import { Button, Grid, Link as MUILink, Typography, Container } from "@material-ui/core";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { TextField } from "formik-material-ui";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import FormControl from "@material-ui/core/FormControl";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { APIRequest } from "../../utils";
import { connect } from "react-redux";
import { login } from "../../actions/userActions";
import { useSnackbar } from "notistack";

const StyledButton = withStyles((theme) => ({
  contained: {
    fontFamily: "avenir-next-lt-pro, sans-serif",
    color: "#ffffff",
    backgroundColor: "#0061d5",
    borderRadius: "4px",
    display: "inline-block",
    border: "none",
    textDecoration: "none",
    fontSize: "1rem",
    transition: "background 100ms ease,border-color 100ms ease",
    lineHeight: "1",
    minHeight: "3em",
    "&:hover": {
      opacity: "0.5",
      backgroundColor: "#0979ff",
    },
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  formTitle: {
    fontSize: "1.75rem", // make it consistent as the packages page
    lineHeight: "2.25rem", // make it consistent as the packages page
    marginBottom: theme.spacing(2),
    letterSpacing: 0, // override a negative setting in MuiTypography
    fontFamily: "itc-avant-garde-gothic-bold",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorLink: {
    "&:hover": {
      color: "red",
      cursor: "context-menu",
    },
    fontWeight: 600,
    textDecoration: "none",
  },
  link: {
    "&:hover": {
      color: "red",
      cursor: "context-menu",
    },
    textDecoration: "underline",
  },
  linksContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  button: {
    root: {
      "&hover": {
        opacity: 0.3,
        backgroundColor: "#ef3e3d",
      },
    },
  },
}));

function LogIn(props) {
  let { from } = props.location.state || { from: { pathname: "/" } };
  sessionStorage.setItem("from", JSON.stringify({ from }));
  const { enqueueSnackbar } = useSnackbar(); //, closeSnackbar

  const classes = useStyles();
  const [states, setStates] = useState({
    password: "",
    showPassword: false,
  });
  const handleClickShowPassword = () => {
    setStates({ ...states, showPassword: !states.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleLogin = (values) => {
    const loginAPI = props.login(values.email, values.password);
    loginAPI.then((res) => {
      if (res) {
        props.history.replace(from);
        //snackbar settings
        const message = "Successfully loggin in.";
        enqueueSnackbar(message, {
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          variant: "success",
          autoHideDuration: 1000,
        });
      } else {
        //snackbar settings
        const message = "Wrong username or password";
        enqueueSnackbar(message, {
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          variant: "error",
          autoHideDuration: 1000,
        });
      }
    });
  };

  async function validateEmail(value) {
    let error;
    const bodyContent = {
      passport: value,
      passportType: "email",
    };
    const SERVER_URL = process.env.REACT_APP_SERVER_URL ?? "http://localhost:4000";
    const res = await APIRequest({
      url: `${SERVER_URL}/proxyAuth/passport/hasRegister`,
      method: "POST",
      body: bodyContent,
    });
    const data = await res.json();
    if (!data.result.hasRegister) {
      error = (
        <Typography variant="caption" component="p">
          The email you’ve entered doesn’t match any account.
          <MUILink
            component={Link}
            className={classes.errorLink}
            to={{
              pathname: "/signup",
              state: { from },
            }}
          >
            Sign up for an account.
          </MUILink>
        </Typography>
      );
    }
    return error;
  }

  const validationSchema = yup.object().shape({
    password: yup.string().required("Required"),

    email: yup.string().required("Required").email("Invalid Email"),
  });

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Typography component="h1" variant="h4" className={classes.formTitle}>
          Log In
        </Typography>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={async (values, { setSubmitting }) => {
            setTimeout(() => {
              setSubmitting(false);
            }, 500);
            handleLogin(values);
            // alert(JSON.stringify(values,2,null));
          }}
          validationSchema={validationSchema}
        >
          {({ submitForm, isSubmitting, touched, errors }) => (
            <Form className={classes.form}>
              <Fade bottom cascade>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      variant="outlined"
                      name="email"
                      type="text"
                      label="Email"
                      autoComplete="current-password"
                      validate={validateEmail}
                      autoFocus
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Field
                        component={TextField}
                        variant="outlined"
                        name="password"
                        type={states.showPassword ? "text" : "password"}
                        // value={states.password}
                        label="Password"
                        autoComplete="current-password"
                        fullWidth
                        // onChange={handleChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                                {states.showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    {/* <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                /> */}
                    <StyledButton
                      variant="contained"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={submitForm}
                      disabled={isSubmitting}
                    >
                      Log In
                    </StyledButton>
                  </Grid>
                  <Grid container style={{ padding: "0 8px" }}>
                    <Grid item xs>
                      <MUILink className={classes.errorLink} component={Link} to="/forgotPassword" variant="body2">
                        Forgot password?
                      </MUILink>
                    </Grid>
                    <Grid item>
                      <MUILink
                        component={Link}
                        variant="body2"
                        className={classes.errorLink}
                        to={{
                          pathname: "/signup",
                          state: { from },
                        }}
                      >
                        {"Don't have an account? Sign Up"}
                      </MUILink>
                    </Grid>
                  </Grid>
                </Grid>
              </Fade>
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  );
}

export default connect(
  (state) => ({
    failedLogin: state.user.failedLogin,
    isLogin: state.user.isLogin,
  }),
  {
    login,
  }
)(LogIn);
