import React from "react";
import { Link } from "react-router-dom";
import { Container, Typography, Button, Box } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Fade from "react-reveal/Fade";

const StyledButton = withStyles((theme) => ({
  root: {
    backgroundColor: "#ef3e3d",
    borderRadius: "300px",
    "&:hover": {
      opacity: "0.5",
      backgroundColor: "#ef3e3d",
    },
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  text: {
    marginTop: theme.spacing(16),
  },
  button: {
    marginTop: theme.spacing(10),
    justify: "center",
  },
}));

export default function AskQuote(props) {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <Fade bottom cascade>
        <div className={classes.paper}>
          <Typography className={classes.text} variant="h5" align="center" margin={2}>
            Do you already have a quote?
          </Typography>

          <Box margin={2} style={{ width: "50%" }}>
            <Link to="/quote">
              <StyledButton variant="contained" color="primary" fullWidth onClick={() => props.history.push("/quote")}>
                YES
              </StyledButton>
            </Link>
          </Box>

          <Box margin={2} style={{ width: "50%" }}>
            <StyledButton variant="contained" color="primary" onClick={() => props.history.push("/chooseLocation")} fullWidth>
              NO
            </StyledButton>
          </Box>
        </div>
      </Fade>
    </Container>
  );
}
