import React from "react";
import { format, parseISO } from "date-fns";
import { Box, Typography } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    marginTop: theme.spacing(5),
  },
  quoteBlock: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  item: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  label: {
    flexGrow: "1",
    textAlign: "left",
    fontSize: 15,
    fontWeight: "bold",
  },
  value: {
    flexGrow: "1",
    textAlign: "right",
    fontSize: 20,
  },
  expiredQuote: {
    display: "inline-flex",
    color: "red",
    fontSize: 15,
  },
}));

const QuoteBlock = ({ children, style }) => {
  const classes = useStyles();
  return (
    <div className={classes.quoteBlock} style={style}>
      {children}
    </div>
  );
};

const Item = ({ label, value, children, style }) => {
  const classes = useStyles();
  return (
    <div className={classes.item} style={style}>
      <div className={classes.label}>{label}</div>
      <div className={classes.value}>{value ? value : children}</div>
    </div>
  );
};

const CreatedAtItem = ({ value }) => {
  if (value) {
    const createdAtTime = parseISO(value);
    const formatted = format(createdAtTime, "MM/dd/yyyy hh:mm a");
    return <Item label="Created At" value={formatted} style={{ display: "inline-flex" }} />;
  } else return <></>;
};

const ValidThruItem = ({ value }) => {
  const classes = useStyles();
  if (value) {
    const validThruTime = parseISO(value);
    const formatted = format(validThruTime, "MM/dd/yyyy hh:mm a");
    const expired = validThruTime < new Date();
    return (
      <Item label="Valid Through">
        <div className={classes.value}>{formatted}</div>
        {expired ? (
          <div className={classes.expiredQuote}>
            <ErrorIcon color="error" fontSize="small" style={{ marginRight: "4px" }} /> This quote has expired
          </div>
        ) : (
          <></>
        )}
      </Item>
    );
  } else return <></>;
};

const DocumentItem = ({ value }) => {
  return <></>;
  // const classes = useStyles();
  // if (value) {
  //   const SERVER_URL = process.env.REACT_APP_SERVER_URL ?? "http://localhost:4000";
  //   return (
  //     <Item label="Quote Ducument" style={{ alignItems: "center" }}>
  //       <a rel="noreferrer" target="_blank" href={`${SERVER_URL}${value}`}>
  //         <DescriptionIcon fontSize="large" />
  //       </a>
  //     </Item>
  //   );
  // } else return <></>;
};

const CurrencyItem = ({ label, value }) => {
  if (typeof value != undefined && value !== null) {
    return <Item label={label} value={`$${value.toLocaleString()}`} />;
  } else return <></>;
};

export default function HasQuote({ quote }) {
  const classes = useStyles();
  const totalAmount = (quote?.billAmount ?? 0) + (quote?.deposit ?? 0) + (quote?.implementation ?? 0);
  return quote ? (
    <div className={classes.paper}>
      <Box>
        <Typography className={classes.text} align="left" variant="h4">
          Quote found:
        </Typography>
      </Box>
      <QuoteBlock>
        <Item label="Restaurant / Chain" value={quote.name} />
        <CreatedAtItem value={quote.createdAt} />
        <ValidThruItem value={quote.expiresAt} />
        <DocumentItem value={quote.attachment?.publicUrl} />
        <Item label="Number of Locations" value={quote.locations} />
        <Item label="Subscription Type" value={quote.billFrequency === "M" ? "Monthly" : "Yearly"} />
      </QuoteBlock>
      <QuoteBlock style={{ borderTop: "1px solid #ccc" }}>
        <CurrencyItem label="Subscription Charge" value={quote.billAmount} />
        <CurrencyItem label="Deposit" value={quote.deposit} />
        <CurrencyItem label="Implementation Service" value={quote.implementation} />
      </QuoteBlock>
      <QuoteBlock style={{ borderTop: "1px solid #ccc" }}>
        <CurrencyItem label="TOTAL" value={totalAmount} />
      </QuoteBlock>
    </div>
  ) : (
    <></>
  );
}
