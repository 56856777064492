import { APIRequest } from "../utils";
class Auth {
  /**
   * Authenticate a user. Save a token string in Local Storage
   *
   * @param {string} token
   */
  static async signin(email, password) {
    const bodyContent = {
      passport: email,
      pwd: password,
      passportType: "email",
    };

    const SERVER_URL = process.env.REACT_APP_SERVER_URL ?? "http://localhost:4000";
    const res = await APIRequest({
      url: `${SERVER_URL}/proxyAuth/passport/login`,
      method: "POST",
      body: bodyContent,
    });
    const data = await res.json();
    if (data.result) {
      localStorage.setItem("accessToken", data.result.accessToken);
      localStorage.setItem("refreshToken", data.result.refreshToken);
      return true;
    } else if (data.error) {
      alert(data.error.message);
      return false;
    }
  }

  static isUserAuthenticated() {
    return localStorage.getItem("accessToken") !== null;
  }

  /**
   * Deauthenticate a user. Remove a token from Local Storage.
   *
   */
  static signout() {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("email");
  }

  /**
   * Get a token value.
   *
   * @returns {string}
   */

  static getToken() {
    return localStorage.getItem("accessToken");
  }
}

export default Auth;
