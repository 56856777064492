import {
  SELECT_LOCATION_MODE,
  SELECT_PAYMODE,
  ADD_TO_ORDER,
  REMOVE_FROM_ORDER,
  COPY_LOCATION,
  USEREMVDATA,
  FINALIZEORDER,
  FETCH_QUOTE_ID,
  ADD_SINGLE_PLAN,
  ADD_MULTIPLE_PLANS,
  CLEAN_ORDER,
  UNDELETE_ITEM,
} from "../types";
import { POST_PAYMENT_INFO } from "../queries/Payment";
import { callGraphQlApi } from "../utils";
var shortid = require("shortid");

export const selectLocationMode = (selectedLocationMode) => (dispatch) => {
  dispatch({
    type: SELECT_LOCATION_MODE,
    payload: { selectedLocationMode },
  });
};

export const selectPayMode = (payMode) => (dispatch) => {
  dispatch({
    type: SELECT_PAYMODE,
    payload: { selectedPayMode: payMode },
  });
  // localStorage.setItem("selectedPayMode", JSON.stringify(payMode));
};

export const cleanOrder = () => (dispatch) => {
  dispatch({
    type: CLEAN_ORDER,
    payload: { orderItems: [] },
  });
};

export const addToOrder = () => (dispatch, getState) => {
  const currentPlan = getState().plan.selectedPlan;
  let currentOrder = getState().order.orderItems;
  if (currentOrder.map((x) => x.id).includes(currentPlan.id)) {
    currentOrder.forEach((x) => {
      if (x.id === currentPlan.id) {
        Object.keys(x).forEach(function (key) {
          x[key] = currentPlan[key];
        });
      }
    });
  } else {
    currentOrder.push(currentPlan);
  }

  dispatch({
    type: ADD_TO_ORDER,
    payload: { orderItems: currentOrder },
  });
  // localStorage.setItem("orderItems", JSON.stringify(updatedOrder));
};

export const removeFromOrder = (plan) => (dispatch, getState) => {
  const orderItems = getState().order.orderItems.slice();
  const updatedOrderItems = orderItems.filter((x) => x.id !== plan.id);
  const deletedItem = orderItems.filter((x) => x.id === plan.id);
  dispatch({
    type: REMOVE_FROM_ORDER,
    payload: { orderItems: updatedOrderItems, deletedItem: deletedItem },
  });
  // localStorage.setItem("orderItems", JSON.stringify(updatedOrderItems));
};

export const undeleteItem = () => (dispatch, getState) => {
  let orderItems = getState().order.orderItems;
  const deletedItem = getState().order.deletedItem[0];
  orderItems.push({ ...deletedItem });
  dispatch({
    type: UNDELETE_ITEM,
    payload: { orderItems: orderItems, deletedItem: {} },
  });
  // localStorage.setItem("orderItems", JSON.stringify(updatedOrderItems));
};

export const copyLocation = () => (dispatch, getState) => {
  const orderItems = getState().order.orderItems;
  const copyedLocation = getState().order.orderItems[0];
  const newLocation = { ...copyedLocation, id: shortid.generate() };
  const updatedOrderItems = [...orderItems, newLocation];
  dispatch({
    type: COPY_LOCATION,
    payload: { orderItems: updatedOrderItems },
  });
  // localStorage.setItem("selectedPayMode", JSON.stringify(payMode));
};

export const finalizeOrder = () => (dispatch, getState) => {
  const orderItems = getState().order.orderItems;
  const payMode = getState().order.selectedPayMode;
  const itemNumber = orderItems.length;

  // Go through the items in your shopping cart
  //Calculatet the total price for the billedMonthly, billedAnnually, deposit, implementation for your ORDER
  const monthlyBilledTotal = orderItems.reduce((a, c) => a + c.monthlyCharge, 0);
  const annuallyBilledTotal = orderItems.reduce((a, c) => a + c.annuallyCharge, 0);
  const depositBilledTotal = orderItems.reduce((a, c) => a + c.depositCharge, 0);
  const implementationBilledTotal = orderItems.reduce((a, c) => a + c.implementationCharge, 0);
  const amount = (payMode === "monthly" ? monthlyBilledTotal : annuallyBilledTotal) + depositBilledTotal + implementationBilledTotal;
  dispatch({
    type: FINALIZEORDER,
    payload: {
      itemNumber: itemNumber,
      monthlyBilledTotal: monthlyBilledTotal,
      annuallyBilledTotal: annuallyBilledTotal,
      depositBilledTotal: depositBilledTotal,
      implementationBilledTotal: implementationBilledTotal,
      amount: amount,
    },
  });
};

export const storeQuoteId = (quoteID) => (dispatch) => {
  dispatch({
    type: FETCH_QUOTE_ID,
    payload: { quoteID: quoteID },
  });
};

export const userEmvData = (data) => (dispatch) => {
  dispatch({
    type: USEREMVDATA,
    payload: { paymentToken: data.token, expiry: data.expiryDate },
  });
};

export const userPaymentInfo = (paymentInfo) => (dispatch, getState) => {
  const paymentToken = getState().order.paymentToken;
  const expiry = getState().order.expiry;
  const amount = getState().order.amount;
  const orderInfo = getState().order;
  const brand = getState().user.currentUser.restaurantName;
  const quoteID = getState().order.quoteID;
  var variables = {
    brand: quoteID ? "" : brand,
    payment: {
      ...paymentInfo,
      amount: quoteID ? 0 : amount || 100,
      paymentToken: paymentToken,
      expiry: expiry,
    },
    quote: quoteID ?? "",
    accessToken: localStorage.getItem("accessToken"),
    refreshToken: localStorage.getItem("refreshToken"),
    order: quoteID
      ? null
      : {
          selectedPayMode: orderInfo.selectedPayMode,
          monthlyBilledTotal: orderInfo.monthlyBilledTotal,
          annuallyBilledTotal: orderInfo.annuallyBilledTotal,
          onetimeBilled: orderInfo.depositBilledTotal + orderInfo.implementationBilledTotal,
          amount: orderInfo.amount,
          itemNumber: orderInfo.itemNumber,
          orderItems: orderInfo.orderItems,
        },
  };

  console.log(JSON.stringify(variables));
  return callGraphQlApi(POST_PAYMENT_INFO, variables);
};

// **********************************
//     For concise pricing page
// **********************************

export const add_single_plan = (
  plan,
  selectedAddons,
  selectedSetups,
  totalPrice_monthly,
  totalPrice_annually,
  totalPrice_deposit,
  totalPrice_implementation
) => (dispatch, getState) => {
  const { isSingleton, cardConnectConsent, ...rest } = plan;
  const currentPlan = {
    ...rest,
    id: shortid.generate(),
    selectedAddons: plan.addons.filter((x) => selectedAddons.includes(x.key)),
    selectedInstallations: selectedSetups,
    monthlyCharge: totalPrice_monthly,
    annuallyCharge: totalPrice_annually,
    depositCharge: totalPrice_deposit,
    implementationCharge: totalPrice_implementation,
  };

  let currentOrder = getState().order.orderItems;
  currentOrder.splice(0, 1, currentPlan);

  dispatch({
    type: ADD_SINGLE_PLAN,
    payload: { orderItems: currentOrder },
  });
};

export const add_multiple_plans = (
  count,
  plan,
  selectedAddons,
  selectedSetups,
  totalPrice_monthly,
  totalPrice_annually,
  totalPrice_deposit,
  totalPrice_implementation
) => (dispatch, getState) => {
  const { isSingleton, cardConnectConsent, ...rest } = plan;
  let currentOrder = getState().order.orderItems;

  //plans with identifiable ids
  for (let i = 0; i < count; i++) {
    const currentPlan = {
      ...rest,
      id: shortid.generate(),
      selectedAddons: plan.addons.filter((x) => selectedAddons.includes(x.key)),
      selectedInstallations: selectedSetups,
      monthlyCharge: totalPrice_monthly,
      annuallyCharge: totalPrice_annually,
      depositCharge: totalPrice_deposit,
      implementationCharge: totalPrice_implementation,
    };
    currentOrder.push(currentPlan);
  }

  dispatch({
    type: ADD_MULTIPLE_PLANS,
    payload: { orderItems: currentOrder },
  });
};
