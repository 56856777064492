import { getSafe } from "../utils";
import useAxios from "./useAxios";
import { GET_ALL_PLANS } from "../queries/Plan";

const useProducts = () => {
  const response = useAxios(GET_ALL_PLANS);
  return getSafe(() => response.data.allPlans, []);
};

export default useProducts;
