// this is based on https://github.com/nativestack/react-cardpointe-gateway/blob/master/src/CardPointeTokenizer.js
// added the ability to customize iframe CSS
// and greatly simplified
import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";

export default class CardPointeTokenizer extends Component {
  handleTokenEvent = (event) => {
    window.addEventListener(
      "message",
      (event) => {
        if (typeof event.data === "string") {
          const token = JSON.parse(event.data);
          if (token && token.expiry && token.message !== "") {
            this.props.tokenProps.userEmvDataFunc({
              token: token.message,
              expiryDate: token.expiry,
            });
          }
          event.preventDefault();
        }
      },
      false
    );
  };

  render() {
    const thisSite = window.location.href;
    const cardConnectServer = thisSite.match(/localhost/) || thisSite.match(/127\.0\.0\.1/) || thisSite.match(/test/) ? "fts-uat" : "fts";
    const cardConnectUrl = `https://${cardConnectServer}.cardconnect.com/itoke/ajax-tokenizer.html`;
    const cssStyle = this.props.css
      ? `css=${this.props.css}`
      : "css=.error{color:red;border-color:red;}label{font-family:sans-serif;font-size:12px;}input{height:18px;font-size:16px;}body{margin:0px;height:100%}select{height:18px;}";

    const params = new URLSearchParams({
      maskfirsttwo: false,
      useexpiry: true, // tried useexpiryfield, not working
      usemonthnames: false,
      usecvv: true,
      cardnumbernumericonly: true,
      orientation: "horizontal",
      invalidinputevent: true,
      tokenizewheninactive: false,
      enhancedresponse: true,
      formatinput: true,
      cardinputmaxlength: 19, // 16 plus 3 dividers
      placeholdercvv: "CVV",
      placeholdermonth: "01", // no effect
      placeholderyear: "2021", // no effect
      placeholder: "Enter\u00a0Card\u00a0Number", // this is a hack, normal space causes the placeholder to disappear, probably a bug in the iframe code
    });

    const iFrameUrl = cardConnectUrl + "?" + params + "&" + encodeURI(cssStyle).replace(/#/g, "%23").replace(/\+/g, "%2B");

    return (
      <Container style={{ marginLeft: 0, marginRight: 0 }}>
        {/* Start Form for step 1 here!! */}
        {/* onSubmit={ this.handleSubmit } */}
        <Form className="form-renewals" name="order" method="post" id="tokenform">
          {/* This is the Number FG */}
          <Form.Group controlId="tokenEvent" style={this.props.iframeHostStyle}>
            <iframe
              title="CardPointeTokenizer"
              id="tokenframe"
              name="tokenframe"
              src={iFrameUrl}
              frameBorder="0"
              scrolling="no"
              width="100%"
              height="100%"
              onLoad={this.handleTokenEvent}
            />
          </Form.Group>
        </Form>
      </Container>
    );
  }
}
