import React, { useEffect } from "react";
import { Typography, CircularProgress, Button, Box, InputLabel, FormControl, Grid, FormControlLabel, Checkbox } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Formik, Form, Field } from "formik";
import { TextField, Select } from "formik-material-ui";
import * as yup from "yup";
import { US_STATES } from "../../locales/en-us";
import CardPointeTokenizer from "./tokenizer";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { callGraphQlApi } from "../../utils";
import { GET_QUOTE_BY_KEY } from "../../queries/QuoteByKey";
import { connect } from "react-redux";
import { formatMonetaryAmount } from "../../utils";

const StyledButton = withStyles((theme) => ({
  root: {
    fontFamily: "avenir-next-lt-pro, sans-serif",
    borderRadius: "4px",
    display: "inline-block",
    textDecoration: "none",
    fontSize: "1rem",
    transition: "background 100ms ease,border-color 100ms ease",
    minHeight: "3em",
    alignContent: "center",
    lineHeight: "1",
  },
  contained: {
    color: "#ffffff",
    backgroundColor: "#0061d5",
    border: "none",
    "&:hover": {
      opacity: "0.5",
      backgroundColor: "#0979ff",
    },
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(8),
  },
  formTitle: {
    fontSize: "1.75rem", // make it consistent as the packages page
    lineHeight: "2.25rem", // make it consistent as the packages page
    marginBottom: theme.spacing(5),
    letterSpacing: 0, // override a negative setting in MuiTypography
    fontFamily: "itc-avant-garde-gothic-bold",
  },
  subTitle: {
    fontSize: "1rem", // make it consistent as the packages page
    lineHeight: "2rem", // make it consistent as the packages page
    marginBottom: 0,
    letterSpacing: 0, // override a negative setting in MuiTypography
    fontFamily: "itc-avant-garde-gothic-bold",
  },
  text: {
    marginBottom: theme.spacing(1),
  },
  tableValue: {
    textAlign: "right",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    justifyContent: "flex-end",
  },
}));

function CheckoutForm(props) {
  const classes = useStyles();
  const [states, setStates] = React.useState({
    quote: {},
    isLoading: true,
    taxSales: 0,
    isBillingAddrTheSameAsBusiness: false,
  });

  const yupStringRequired = yup.string().required("Required");
  const validationSchema = yup.object().shape({
    cardholderName: yupStringRequired,
    billingAddress: yupStringRequired,
    billingCity: yupStringRequired,
    billingState: yupStringRequired,
    billingZipCode: yupStringRequired,
    businessAddress: yupStringRequired,
    businessCity: yupStringRequired,
    businessState: yupStringRequired,
    businessZipCode: yupStringRequired,
  });

  //fetch Sales tax
  const fetchSalesTax = (zipCode) => {
    const baseUrl = "https://account-test.trytabletop.com/publicApi/taxLookup?zipCode=";
    const endpoint = [baseUrl, zipCode].join("");
    fetch(endpoint)
      .then((res) => res.json())
      .then((data) => {
        setStates({ ...states, taxSales: data.txbService === "N" ? 0 : data.taxSales });
      });
  };

  //fetch quote info for quote purchase
  const fetchQuoteInfo = (quoteId) => {
    setStates({ ...states, isLoading: true });
    callGraphQlApi(GET_QUOTE_BY_KEY, { ID: quoteId })
      .then((response) => {
        if (response.data?.data?.allQuotes?.length > 0) {
          const quoteInfo = response.data.data.allQuotes[0];
          // console.log(JSON.stringify(quoteInfo));
          let totalPayment = +quoteInfo.billAmount + +quoteInfo.deposit + +quoteInfo.implementation + +quoteInfo.hardware + +quoteInfo.salesTax;
          setStates({
            quote: {
              ...quoteInfo,
              totalPayment,
            },
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        setStates({ ...states, isLoading: false });
        console.log("Quote not found. Please check your input and try again.");
      });
  };

  useEffect(() => {
    if (props.quoteID) {
      fetchQuoteInfo(props.quoteID);
      console.log("Quote exist! Quote is ", states.quote);
    }
  }, []);

  const onSubmit = async (values, { setFieldError, setSubmitting }) => {
    console.log(JSON.stringify(values));
    setTimeout(() => {
      setSubmitting(false);
    }, 3000);
    const res = await props.userPaymentInfo(values);
    switch (res.data.data?.checkout?.status) {
      case "OK":
        props.history.replace("/orderConfirmation");
        break;
      // case "INVALID TOKEN":
      // setFieldError("billingAddress", "Invalid card. Please correct your card number.");
      // break;
      case "AVS MISMATCH":
        setFieldError("billingAddress", "Address error. Please correct your billing address.");
        break;
      default:
        alert(res.data.data?.checkout?.status);
        break;
    }
  };

  const initialValues = {
    cardholderName: "",
    billingAddress: "",
    billingCity: "",
    billingState: "",
    billingZipCode: "",
    businessAddress: "",
    businessCity: "",
    businessState: "",
    businessZipCode: "",
  };

  const cardholderNameInput = React.createRef();

  const tokenProps = {
    userEmvDataFunc: (emvData) => {
      props.userEmvData(emvData);
      // cardholderNameInput.current.focus();
    },
  };

  const iframeStyles = [
    "input,select {font-family:'Roboto','Helvetica','Arial',sans-serif;font-size:1em;color:rgba(0,0,0,0.87);padding:15px 14px;margin:0 0 5px 0;border:1px solid rgba(0,0,0,0.23);border-radius:5px;}",
    "input:focus, select:focus {outline:none}",
    "label {font-family:'Roboto','Helvetica','Arial',sans-serif;font-size:0.8em;color:rgba(0,0,0,0.54);line-height:1.5;letter-spacing: 0.00938em;}",
    "#ccnumfield {width:calc(100% - 32px);}",
    "#ccexpirylabel {margin-right:12px; width: calc(12rem + 12px); display:inline-block;}",
    "#ccexpirymonth {width:6rem}",
    "#ccexpiryyear {width:6rem}",
    "#cccvvlabel {display:none}",
    "#cccvvfield {width: 4rem}",
    ".error{border-color:red;}",
  ];

  return (
    <div className={classes.paper}>
      <Typography component="h1" className={classes.formTitle}>
        Checkout
      </Typography>

      {props.quoteID ? (
        <Box css={{ marginBottom: "0", width: "100%", fontSize: "120%", lineHeight: 1.5, fontFamily: "avenir-next-lt-pro", color: "#393a3d" }}>
          <table style={{ width: "100%" }}>
            <tr>
              <td>{states.quote.billFrequency === "A" ? "Annual" : "Monthly"} subscription</td>
              <td className={classes.tableValue}>{formatMonetaryAmount(states.quote.billAmount, props, states)}</td>
            </tr>
            <tr>
              <td>Deposit</td>
              <td className={classes.tableValue}>{formatMonetaryAmount(states.quote.deposit, props, states)}</td>
            </tr>
            <tr>
              <td>Implementation</td>
              <td className={classes.tableValue}>{formatMonetaryAmount(states.quote.implementation, props, states)}</td>
            </tr>
            <tr>
              <td>Hardware purchase</td>
              <td className={classes.tableValue}>{formatMonetaryAmount(states.quote.hardware, props, states)}</td>
            </tr>
            <tr>
              <td>Tax ({states.quote.salesTaxRate}%)</td>
              <td className={classes.tableValue}>{formatMonetaryAmount(states.quote.salesTax, props, states)}</td>
            </tr>
            <tr>
              <td style={{ fontWeight: "bold" }}>Total:</td>
              <td className={classes.tableValue} style={{ fontWeight: "bold" }}>
                {formatMonetaryAmount(states.quote.totalPayment, props, states)}
              </td>
            </tr>
          </table>
        </Box>
      ) : (
        <Box css={{ marginBottom: "0", width: "100%", fontSize: "120%", lineHeight: 1.5, fontFamily: "avenir-next-lt-pro", color: "#393a3d" }}>
          <table style={{ width: "100%" }}>
            <tr>
              <td>{props.selectedPayMode === "annually" ? "Annual" : "Monthly"} subscription</td>
              <td className={classes.tableValue}>
                {formatMonetaryAmount(
                  props.selectedPayMode === "annually" ? Math.ceil(props.annuallyBilledTotal / 12) : props.monthlyBilledTotal,

                  props,

                  states
                )}
                {props.selectedPayMode === "annually" && " * 12mo"}
              </td>
            </tr>
            <tr>
              <td>Deposit</td>
              <td className={classes.tableValue}>{formatMonetaryAmount(props.depositBilledTotal, props, states)}</td>
            </tr>
            <tr>
              <td>Implementation</td>
              <td className={classes.tableValue}>{formatMonetaryAmount(props.implementationBilledTotal, props, states)}</td>
            </tr>
            <tr>
              <td>Sales Tax {states.taxSales > 0 && `(${(states.taxSales * 100).toFixed(2)}%)`}</td>
              <td className={classes.tableValue}>{formatMonetaryAmount((props.amount - props.depositBilledTotal) * states.taxSales, props, states)}</td>
            </tr>
            <tr>
              <td style={{ fontWeight: "bold" }}>Total:</td>
              <td className={classes.tableValue} style={{ fontWeight: "bold" }}>
                {formatMonetaryAmount(props.amount * (1 + states.taxSales) - states.taxSales * props.depositBilledTotal, props, states)}
              </td>
            </tr>
          </table>
        </Box>
      )}

      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ submitForm, isSubmitting, values, setFieldValue }) => (
          <Form className={classes.form}>
            {/* Buiness Address Section */}
            <section style={{ width: "100%", margin: " 8px 0", padding: "0" }}>
              <div className={classes.businessAddr__title}>
                <Typography component="h5" variant="h5" style={{ margin: "0.5em 0 0 0" }}>
                  Business Address
                </Typography>
                <p>We use this to determine your sales tax.</p>
              </div>

              <Grid container spacing={2} style={{ marginTop: "8px" }}>
                <Grid item xs={12}>
                  <Field component={TextField} variant="outlined" name="businessAddress" type="text" label="Business Address" fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <Field component={TextField} variant="outlined" name="businessCity" type="text" label="City" fullWidth />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <FormControl variant="outlined" style={{ width: "100%" }}>
                    <InputLabel>State</InputLabel>
                    <Field native component={Select} variant="outlined" name="businessState" label="State">
                      <option aria-label="None" value="" />
                      {Object.keys(US_STATES).map((abbr) => (
                        <option key={abbr} value={abbr}>
                          {abbr}
                        </option>
                      ))}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Field
                    component={TextField}
                    variant="outlined"
                    name="businessZipCode"
                    onBlur={(e) => fetchSalesTax(e.target.value)}
                    type="text"
                    label="ZIP Code"
                    style={{ width: "100%" }}
                  />
                </Grid>
              </Grid>
            </section>

            {/* Payment Method Section */}
            <section style={{ width: "100%", margin: "8px 0", padding: "0" }}>
              <Typography component="h5" variant="h5" style={{ margin: "1em 0 0.5em 0" }}>
                Payment Method
              </Typography>
              <Box css={{ padding: "0 4px 4px", backgroundColor: "#CFC", borderRadius: "10px", width: "100%" }}>
                <CardPointeTokenizer tokenProps={tokenProps} css={iframeStyles.join("")} iframeHostStyle={{ height: "14rem" }} />
              </Box>

              <Field
                style={{ margin: "8px 0 0" }}
                component={TextField}
                variant="outlined"
                name="cardholderName"
                type="text"
                label="Cardholder Name"
                fullWidth
                inputRef={cardholderNameInput}
              />
            </section>

            {/* Billing Address Section */}
            <section style={{ width: "100%", margin: "8px 0", padding: "0" }}>
              <div>
                <Typography component="h5" variant="h5" style={{ margin: "1em 0 0 0" }}>
                  Billing Address
                </Typography>
                <FormControlLabel
                  style={{ display: "table" }}
                  control={
                    <div style={{ display: "table-cell" }}>
                      <Checkbox
                        size="small"
                        checked={states.isBillingAddrTheSameAsBusiness}
                        onChange={async () => {
                          await setStates({ ...states, isBillingAddrTheSameAsBusiness: !states.isBillingAddrTheSameAsBusiness });
                          if (!states.isBillingAddrTheSameAsBusiness) {
                            setFieldValue("billingAddress", values.businessAddress);
                            setFieldValue("billingCity", values.businessCity);
                            setFieldValue("billingState", values.businessState);
                            setFieldValue("billingZipCode", values.businessZipCode);
                          } else {
                            setFieldValue("billingAddress", "");
                            setFieldValue("billingCity", "");
                            setFieldValue("billingState", "");
                            setFieldValue("billingZipCode", "");
                          }
                        }}
                        color="primary"
                      />
                    </div>
                  }
                  label={
                    <Typography variant="caption" component="p">
                      Same as business address
                    </Typography>
                  }
                  labelPlacement="end"
                />
              </div>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    variant="outlined"
                    name="billingAddress"
                    value={values.billingAddress}
                    type="text"
                    label="Billing Address"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field component={TextField} variant="outlined" name="billingCity" value={values.billingCity} type="text" label="City" fullWidth />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <FormControl variant="outlined" style={{ width: "100%" }}>
                    <InputLabel>State</InputLabel>
                    <Field native component={Select} variant="outlined" value={values.billingState} name="billingState" label="State">
                      <option aria-label="None" value="" />
                      {Object.keys(US_STATES).map((abbr) => (
                        <option key={abbr} value={abbr}>
                          {abbr}
                        </option>
                      ))}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Field
                    component={TextField}
                    variant="outlined"
                    name="billingZipCode"
                    value={values.billingZipCode}
                    type="text"
                    label="ZIP Code"
                    style={{ width: "100%" }}
                  />
                </Grid>

                <Grid item container direction="row" justify="flex-end" alignItems="center">
                  <StyledButton variant="outlined" style={{ marginRight: "1em" }} onClick={() => props.history.goBack()}>
                    <ArrowBackIcon />
                  </StyledButton>
                  <StyledButton variant="contained" color="primary" disabled={isSubmitting} onClick={submitForm}>
                    {isSubmitting && <CircularProgress size={16} style={{ marginRight: 8 }} />}
                    Submit Order
                  </StyledButton>
                </Grid>
              </Grid>
            </section>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default connect(
  (state) => ({
    currentUser: state.user.currentUser,
    quoteID: state.order.quoteID,
    selectedPayMode: state.order.selectedPayMode,
    monthlyBilledTotal: state.order.monthlyBilledTotal,
    annuallyBilledTotal: state.order.annuallyBilledTotal,
    depositBilledTotal: state.order.depositBilledTotal,
    implementationBilledTotal: state.order.implementationBilledTotal,
    amount: state.order.amount,
    taxSales: state.user.taxSales,
  }),
  {}
)(CheckoutForm);
