import React from "react";
import usePlans from "../../../hooks/usePlans";
import "./css/pricing.css";
import PricingPageWrapper from "./pricingPageWrapper";
import SubCard2 from "./subCard2";
import { selectPayMode, selectLocationMode, cleanOrder, finalizeOrder } from "../../../actions/orderActions";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { Button } from "@material-ui/core";

function PricingToggle(props) {
  return (
    <nav className="pricing-navigation">
      <div className="container">
        <input
          type="checkbox"
          name="pricing-navigation-toggle-checkbox"
          id="pricing-navigation-toggle-checkbox"
          className="pricing-navigation-toggle-checkbox"
          checked={props.plansToggleIsChecked}
        />
        <div className="pricing-navigation--tabs" aria-labelledby="pricing-heading" role="radiogroup">
          <button
            className={props.plansToggleIsChecked ? "pricing-navigation-tab-active" : "pricing-navigation--button"}
            name="tab-1"
            onClick={props.selectSingleLocation}
          >
            Single Location
          </button>
          <button
            className={props.plansToggleIsChecked ? "pricing-navigation--button" : "pricing-navigation-tab-active"}
            onClick={props.selectMultipleLocation}
          >
            Multiple Locations
          </button>
        </div>
        <div className="pricing-toggle">
          <input
            type="checkbox"
            name="pricing-toggle-checkbox"
            id="pricing-toggle-checkbox"
            className="pricing-toggle-checkbox"
            checked={props.pricingToggleIsChecked}
          />
          <button

                       className="pricing-toggle-button pricing-toggle-button__before"

                       onClick={() => {
              
              props.selectPayMode("monthly");
              
              props.finalizeOrder();
            
            }}
          
          >
            Monthly
          </button>
          <label for="pricing-toggle-checkbox" className="pricing-toggle-label" onClick={() => props.togglePaymode()}></label>
          <button
           
            className="pricing-toggle-button pricing-toggle-button__after"
           
            onClick={() => {
              
              props.selectPayMode("annually");
              
              props.finalizeOrder();
            
            }}
          
          >
            Annually
            <span className="pricing-toggle-discount">
              (Save up to{" "}
              <span id="toggle-discount-percentage" className="toggle-discount-percentage">
                16%
              </span>
              *)
            </span>
          </button>
        </div>
      </div>
    </nav>
  );
}

function PricingPlans(props) {
  return (
    <div className="pricing-plans-group">
      <div className="pricing-plans-row">
        {props.plans &&
          props.plans.length > 0 &&
          props.plans
            .filter((plan) => plan.isActive === true)
            .map((plan) => (
              <SubCard2
                {...props}
                key={plan.key.toString()}
                plan={plan}
                pricingToggleIsChecked={props.pricingToggleIsChecked}
                plansToggleIsChecked={props.plansToggleIsChecked}
              />
            ))}
      </div>
    </div>
  );
}

function SwitchDialog(props) {
  return (
    <Dialog open={props.isDialogOpen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogContent>
        <DialogContentText id="alert-dialog-description">The items in your shopping cart will be removed.</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.continueSwitch} color="primary">
          Continue
        </Button>
        <Button onClick={props.undoSwitch} color="primary" autoFocus>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function ConcisePricing(props) {
  const plans = usePlans();
  const [states, setStates] = React.useState({
    isDialogOpen: false,
    toGoLocationMode: "",
  });
  const togglePaymode = () => {
    if (props.selectedPayMode === "annually") {
      props.selectPayMode("monthly");
      props.finalizeOrder();
    } else {
      props.selectPayMode("annually");
      props.finalizeOrder();
    }
  };

  const selectMultipleLocation = () => {
    if (props.itemNumber > 0) {
      setStates({ isDialogOpen: true, toGoLocationMode: "multiple" });
    } else {
      props.selectLocationMode("multiple");
    }
  };

  const selectSingleLocation = () => {
    if (props.itemNumber > 0) {
      setStates({ isDialogOpen: true, toGoLocationMode: "single" });
    } else {
      props.selectLocationMode("single");
    }
  };

  const continueSwitch = () => {
    props.cleanOrder();
    props.finalizeOrder();
    props.selectLocationMode(states.toGoLocationMode);
    setStates({ isDialogOpen: false, toGoLocationMode: "" });
  };

  const undoSwitch = () => {
    setStates({ isDialogOpen: false, toGoLocationMode: "" });
  };

  return (
    <PricingPageWrapper {...props}>
      <PricingToggle
        pricingToggleIsChecked={props.selectedPayMode === "annually"}
        plansToggleIsChecked={props.selectedLocationMode === "single"}
        togglePaymode={togglePaymode}
        selectMultipleLocation={selectMultipleLocation}
        selectSingleLocation={selectSingleLocation}
        {...props}
      />
      <PricingPlans
        {...props}
        plans={plans}
        pricingToggleIsChecked={props.selectedPayMode === "annually"}
        plansToggleIsChecked={props.selectedLocationMode === "single"}
      />
      <SwitchDialog continueSwitch={continueSwitch} undoSwitch={undoSwitch} isDialogOpen={states.isDialogOpen} />
    </PricingPageWrapper>
  );
}

export default connect(
  (state) => ({
    itemNumber: state.order.itemNumber,
    selectedLocationMode: state.order.selectedLocationMode,
    selectedPlan: state.plan.selectedPlan,
    currentUser: state.user.currentUser,
    selectedPayMode: state.order.selectedPayMode,
  }),
  {
    selectPayMode,
    selectLocationMode,
    cleanOrder,
    finalizeOrder,
  }
)(ConcisePricing);
