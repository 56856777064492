import React from "react";
import { Typography, Button, Box } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { callGraphQlApi } from "../../../utils";
import { GET_QUOTE_BY_KEY } from "../../../queries/QuoteByKey";

const StyledButton = withStyles((theme) => ({
  root: {
    backgroundColor: "#ef3e3d",
    borderRadius: "300px",
    "&:hover": {
      opacity: "0.5",
      backgroundColor: "#ef3e3d",
    },
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  text: {
    marginTop: theme.spacing(15),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default function HasQuote({ setQuote }) {
  const classes = useStyles();
  return (
    <div className={classes.paper}>
      <Box>
        <Typography className={classes.text} align="left" variant="h4">
          Find your quote
        </Typography>
      </Box>

      <Formik
        initialValues={{
          quote: "",
        }}
        validate={(values) => {
          const errors = {};

          if (values.quote.length < 1) {
            errors.quote = "Required";
          }
          return errors;
        }}
        onSubmit={(values, { setFieldError, setSubmitting }) => {
          callGraphQlApi(GET_QUOTE_BY_KEY, { key: values.quote }).then((response) => {
            if (response.data?.data?.allQuotes?.length > 0) {
              setQuote(response.data.data.allQuotes[0]);
            } else {
              setFieldError("quote", "Quote not found. Please check your input and try again.");
            }
            setSubmitting(false);
          });
        }}
      >
        {({ submitForm, isSubmitting, touched, errors }) => (
          <Form className={classes.form}>
            <Box margin={2}>
              <Field component={TextField} variant="outlined" name="quote" type="text" label="Quote ID" autoFocus size="small" fullWidth />
            </Box>
            <Box margin={2} className={classes.button}>
              <StyledButton type="submit" fullWidth variant="contained" color="primary" disabled={isSubmitting} onClick={submitForm}>
                Load
              </StyledButton>
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
}
