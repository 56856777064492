import { SELECT_PLAN, FORMAT_PLAN, CLEAR_CURRENT_SELECTEDPLAN, EDIT_PLAN, SELECT_MULTIPLE_PLANS } from "../types"; //SELECT_ADDONS, SELECT_INSTALLATION

export const planReducer = (
  state = {
    currentPlan: {},
    selectedPlans: [],
  },
  action
) => {
  switch (action.type) {
    case SELECT_PLAN:
      return { selectedPlans: action.payload.selectedPlans, currentPlan: action.payload.currentPlan };
    case SELECT_MULTIPLE_PLANS:
      return { selectedPlans: action.payload.selectedPlans };
    case FORMAT_PLAN:
      return { selectedPlans: action.payload.selectedPlans };
    case CLEAR_CURRENT_SELECTEDPLAN:
      return { selectedPlans: null };
    case EDIT_PLAN:
      return { selectedPlans: action.payload.selectedPlans };
    default:
      return state;
  }
};
