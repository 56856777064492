import { useEffect, useState } from "react";
import axios from "axios";

const useAxios = (query) => {
  const [response, setResponse] = useState(null);

  useEffect(() => {
    const SERVER_URL = process.env.REACT_APP_SERVER_URL ?? "http://localhost:4000";
    const fetch = async () => {
      const results = await axios.post(`${SERVER_URL}/admin/api`, { query });
      setResponse(results.data);
    };

    fetch();
  }, []);

  return response;
};

export default useAxios;
