import React from "react";
import Lottie from "react-lottie";
import animationData from "../../../1166-tick.json";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Link, Typography } from "@material-ui/core";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(8),
  },
  link: {
    "&:hover": {
      color: "red",
      cursor: "context-menu",
    },
  },
  button: {
    display: "flex",
    flexDirection: "row",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
    justifyContent: "space-around",
  },
}));

const CompleteInfoContainer = () => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        <Lottie options={defaultOptions} height={50} width={50} />
        <h1 style={{ color: "#0E8900" }}>Payment Complete</h1>
      </div>
    </div>
  );
};

const ReturnLinkContainer = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h4 style={{ color: "#0E8900" }}>
        <Link href="https://trytabletop.com">Return to My Account</Link>
      </h4>
    </div>
  );
};

const ReceiptContainer = (props) => {
  return (
    <div>
      <Typography style={{ textAlign: "center" }}>
        We have sent a copy of your receipt to <strong>{props.user.email}.</strong>
      </Typography>
    </div>
  );
};

function OrderConfirmation(props) {
  const classes = useStyles();

  return (
    <Container maxWidth="sm" component="main" className={classes.paper}>
      <CompleteInfoContainer />
      <ReturnLinkContainer />
      <br />
      <ReceiptContainer user={props.currentUser} />
    </Container>
  );
}

export default connect(
  (state) => ({
    currentUser: state.user.currentUser,
  }),
  {}
)(OrderConfirmation);
