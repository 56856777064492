import React from "react";
import Link from "@material-ui/core/Link";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Container, Typography, Grid, Button } from "@material-ui/core";
import { removeFromOrder, copyLocation, finalizeOrder, undeleteItem } from "../../../actions/orderActions";
import { clearCurrentSelectedPlan, editPlan } from "../../../actions/planActions";
import { connect } from "react-redux";
import { formatMonetaryAmount } from "../../../utils";
import { useSnackbar } from "notistack";

const StyledButton = withStyles((theme) => ({
  root: {
    fontFamily: "avenir-next-lt-pro, sans-serif",
    borderRadius: "4px",
    display: "inline-block",
    textDecoration: "none",
    fontSize: "1rem",
    transition: "background 100ms ease,border-color 100ms ease",
    minHeight: "3em",
    alignContent: "center",
    lineHeight: "1",
  },
  contained: {
    color: "#ffffff",
    backgroundColor: "#0061d5",
    border: "none",
    "&:hover": {
      opacity: "0.5",
      backgroundColor: "#0979ff",
    },
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  page__title: {
    fontSize: "1.75rem", // make it consistent as the packages page
    lineHeight: "2.25rem", // make it consistent as the packages page
    marginBottom: theme.spacing(5),
    letterSpacing: 0, // override a negative setting in MuiTypography
    fontFamily: "itc-avant-garde-gothic-bold",
    textAlign: "center",
  },
  link: {
    "&:hover": {
      color: "red",
      cursor: "context-menu",
    },
  },
  order_content__wrapper: {
    display: "flex",
    flexDirection: "column",
  },
  summary__btn_wrappers: {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: "20px",
  },
  summary__back_btn: {
    marginRight: "2em",
  },
  summary__text__row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "baseline",
    fontFamily: "inherant",
  },
  summary__wrapper: {
    fontSize: "2rem",
    fontWeight: "bold",
    marginTop: "20px",
  },
  summary__text: {
    fontFamily: "itc-avant-garde-gothic-bold",
  },
}));

//************************/
//Components
//************************/

function PageTitle() {
  const classes = useStyles();
  return (
    <Typography component="h1" variant="h4" className={classes.page__title}>
      Order Summary
    </Typography>
  );
}

const OrderItemDetails = (props) => {
  const classes = useStyles();

  return (
    <div key={props.item.id}>
      <Grid container xs={12} sm direction="column">
        <Grid item container justify="space-between" style={{ padding: "0.5rem 0 0", margin: "0 0" }}>
          <Grid item>
            <Typography variant="subtitle1">{props.item.name}</Typography>
          </Grid>
          <Grid item>
            <Typography>
              <Link
                className={classes.link}
                onClick={() => {
                  props.handleDeletePlan(props.item);
                  //snackbar settings
                  const message = "Successfully delete the item.";
                  const action = (key) => (
                    <Button
                      onClick={() => {
                        props.undeleteItem();
                        props.finalizeOrder();
                        props.closeSnackbar(key);
                      }}
                    >
                      UNDO
                    </Button>
                  );
                  props.enqueueSnackbar(message, {
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "center",
                    },
                    variant: "success",
                    autoHideDuration: 3000,
                    action,
                  });
                }}
              >
                Delete
              </Link>
            </Typography>
          </Grid>
        </Grid>
        <Grid item style={{ padding: "0 0 0.5rem", margin: "0 0" }}>
          <Typography variant="body2" color="textSecondary">
            {props.item?.selectedAddons?.length > 0 && <strong>Add-on: </strong>}
            {props.item?.selectedAddons?.map((x) => x.name).join(" + ")}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            <strong>Set-up: </strong>
            Online {props.item?.selectedInstallations?.length > 1 && " + "}
            {props.item?.selectedAddons
              ?.filter((x) => props.item?.selectedInstallations?.includes(x.key))
              .map((x) => x.name)
              .join(" + ")}
          </Typography>
        </Grid>
        <Grid item style={{ padding: "0.5rem 0 0.5rem" }}>
          <SummarizeItem isSum={false} label="Deposit" value={formatMonetaryAmount(props.item.depositCharge)} />
          <SummarizeItem isSum={false} label="Implementation" value={formatMonetaryAmount(props.item.implementationCharge)} />
          {props.selectedPayMode === "monthly" ? (
            <SummarizeItem isSum={false} label="Monthly Charge" value={formatMonetaryAmount(props.item.monthlyCharge)} />
          ) : (
            <SummarizeItem isSum={false} label="Annual Charge" value={`${formatMonetaryAmount(Math.ceil(props.item.annuallyCharge / 12))} * 12 mo`} />
          )}
        </Grid>
      </Grid>
      <hr />
    </div>
  );
};

function OrderSummaryContentWrapper(props) {
  const classes = useStyles();
  return (
    <div className={classes.order_content__wrapper}>
      {props.orderItems.map((item) => (
        <OrderItemDetails
          item={item}
          handleDeletePlan={props.handleDeletePlan}
          enqueueSnackbar={props.enqueueSnackbar}
          undeleteItem={props.undeleteItem}
          closeSnackbar={props.closeSnackbar}
          finalizeOrder={props.finalizeOrder}
          selectedPayMode={props.selectedPayMode}
        />
      ))}
    </div>
  );
}

function SummarizeItem(props) {
  const classes = useStyles();
  const fontFamily = props.isSum ? "itc-avant-garde-gothic-bold" : "";
  const borderBottom = props.isTax ? "1px solid black" : "";
  const paddingBottom = props.isTax ? "10px" : "";
  return (
    <div className={classes.summary__text__row} style={{ borderBottom: borderBottom, paddingBottom: paddingBottom }}>
      <div className={classes.summary__text__label}>
        <Typography align="left" style={{ fontFamily: fontFamily }}>
          {props.label}:
        </Typography>
        {props.isTax ? (
          <Typography component="div" align="left">
            (Calculated in checkout)
          </Typography>
        ) : (
          <Typography component="div" style={{ lineHeight: "1.5" }}>
            {"     "}
          </Typography>
        )}
      </div>
      <div className={classes.summary__text__price}>
        <Typography align="left" style={{ fontFamily: fontFamily }}>
          {props.value}
        </Typography>
      </div>
    </div>
  );
}

function SummarizeSection(props) {
  const classes = useStyles();
  return (
    <div className={classes.summary__wrapper}>
      <div style={{ fontFamily: "itc-avant-garde-gothic-bold" }}>
        <SummarizeItem isSum={true} label="Number of Items" value={props.itemNumber === 0 ? "0" : props.itemNumber} />
        {props.selectedPayMode === "monthly" ? (
          <SummarizeItem
            isSum={true}
            label="Monthly Subscription"
            value={props.monthlyBilledTotal === "" ? "$0" : `${formatMonetaryAmount(props.monthlyBilledTotal)}`}
          />
        ) : (
          <SummarizeItem
            isSum={true}
            label="Annual Subscription"
            value={props.annuallyBilledTotal === "" ? "$0" : `${formatMonetaryAmount(props.annuallyBilledTotal)}`}
          />
        )}
        <SummarizeItem isSum={true} label="Deposit" value={props.depositBilledTotal === "" ? "$0" : `${formatMonetaryAmount(props.depositBilledTotal)}`} />
        <SummarizeItem
          isSum={true}
          label="Implementation"
          value={props.implementationBilledTotal === "" ? "$0" : `${formatMonetaryAmount(props.implementationBilledTotal)}`}
        />

        <SummarizeItem isSum={true} isTax={true} label="Taxes" value="$0.00" />
        <SummarizeItem isSum={true} label="Est. total" value={props.amount === "" ? "$0" : `${formatMonetaryAmount(props.amount)}`} />
      </div>

      <div className={classes.summary__btn_wrappers}>
        <StyledButton variant="outlined" color="primary" onClick={() => props.history.goBack()} className={classes.summary__back_btn}>
          <ArrowBackIcon />
        </StyledButton>
        <StyledButton
          color="primary"
          variant="contained"
          disabled={props.orderItems.length < 1}
          onClick={() => props.history.push({ pathname: "/checkout", state: { from: props.location } })}
        >
          Checkout
        </StyledButton>
      </div>
    </div>
  );
}

const RedirectCard = (props) => {
  const classes = useStyles();
  return (
    <Grid container direction="column" justify="center" alignItems="center" spacing={3} className={classes.paper}>
      <Grid item xs>
        <Typography component="h3" align="center" variant="h3">
          Your cart is empty
        </Typography>
      </Grid>
      <Grid
        item
        xs
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <StyledButton color="primary" variant="contained" onClick={() => props.history.push("/")}>
          Go to homepage
        </StyledButton>
      </Grid>
    </Grid>
  );
};

function OrderSummary(props) {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  function handleDeletePlan(plan) {
    props.clearCurrentSelectedPlan();
    props.removeFromOrder(plan);
    props.finalizeOrder();
  }

  if (props.itemNumber === 0) {
    return <RedirectCard {...props} />;
  } else {
    return (
      <Container component="main" maxWidth="sm" className={classes.paper}>
        <PageTitle />
        <OrderSummaryContentWrapper
          {...props}
          handleDeletePlan={handleDeletePlan}
          enqueueSnackbar={enqueueSnackbar}
          closeSnackbar={closeSnackbar}
          undeleteItem={props.undeleteItem}
          finalizeOrder={props.finalizeOrder}
        />
        <SummarizeSection {...props} />
      </Container>
    );
  }
}

export default connect(
  (state) => ({
    selectedLocationMode: state.order.selectedLocationMode,
    selectedPayMode: state.order.selectedPayMode,

    orderItems: state.order.orderItems,
    itemNumber: state.order.itemNumber,
    amount: state.order.amount,
    monthlyBilledTotal: state.order.monthlyBilledTotal,
    annuallyBilledTotal: state.order.annuallyBilledTotal,
    depositBilledTotal: state.order.depositBilledTotal,
    implementationBilledTotal: state.order.implementationBilledTotal,
  }),
  {
    copyLocation,
    removeFromOrder,
    clearCurrentSelectedPlan,
    editPlan,
    finalizeOrder,
    undeleteItem,
  }
)(OrderSummary);
