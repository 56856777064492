export const GET_QUOTE_BY_KEY = `
query findQuoteByKey($ID: ID!) {
  allQuotes(where: { id: $ID }) {
    billFrequency
    billAmount
    deposit
    implementation
    hardware
    salesTax,
    salesTaxRate,
  }
}
`;
