import React, { useState } from "react";
import { Container, CssBaseline } from "@material-ui/core";
import HasQuote from "../components/ALTBuildPackage/quote/hasQuote";
import QuoteDetail from "../components/ALTBuildPackage/quote/quoteDetail";

// quote code input
// load quote
// quote not found
// quote found
// quote detail
// link to check out
// handle quote code as link parameter

export default function QuotePage() {
  const [quote, setQuote] = useState();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <HasQuote setQuote={setQuote} />
      <QuoteDetail quote={quote} />
    </Container>
  );
}
