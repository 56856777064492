export const GET_ALL_PLANS = `
query {
	allPlans(sortBy: billedMonthly_ASC) {
		key
		name
		description
		billedMonthly
		billedYearly
		implementation
    isActive
    isPopular
    isSingleton
    cardConnectConsent
		cardContentAvailable(sortBy: displayOrder_ASC) {
			key
			name
			description
		}
		cardContentUnavailable(sortBy: displayOrder_ASC) {
			key
			name
			description
		}
		includes(sortBy: displayOrder_ASC) {
			key
			name
			displayOrder
			billedMonthly
			billedYearly
			deposit
			implementation
			description
		}
		addons(sortBy: displayOrder_ASC) {
			key
			name
			displayOrder
			billedMonthly
			billedYearly
			deposit
			implementation
			description
		}
	}
}


`

