import React from "react";
import { connect } from "react-redux";
import { Container, CssBaseline } from "@material-ui/core";
import CheckoutForm from "../components/checkout/checkoutForm";
import { userEmvData } from "../actions/orderActions";
import { userPaymentInfo } from "../actions/orderActions";
import { storeQuoteId } from "../actions/orderActions";
import { useLocation } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation()?.search);
}

function CheckoutPage(props) {
  let query = useQuery();
  const quoteID = query.get("q");

  if (quoteID) {
    props.storeQuoteId(quoteID);
    console.log("quoteID: ", quoteID);
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <CheckoutForm {...props} userEmvData={props.userEmvData} userPaymentInfo={props.userPaymentInfo} />
    </Container>
  );
}

export default connect((state) => ({}), {
  userEmvData,
  userPaymentInfo,
  storeQuoteId,
})(CheckoutPage);
