import {
  SELECT_LOCATION_MODE,
  SELECT_PAYMODE,
  ADD_TO_ORDER,
  REMOVE_FROM_ORDER,
  COPY_LOCATION,
  USEREMVDATA,
  USERPAYMENTINFO,
  FINALIZEORDER,
  FETCH_QUOTE_ID,
  ADD_SINGLE_PLAN,
  ADD_MULTIPLE_PLANS,
  CLEAN_ORDER,
  UNDELETE_ITEM,
} from "../types";

export const orderReducer = (
  //placeholder
  state = {
    quoteID: "",
    selectedLocationMode: "single",
    selectedPayMode: "annually",
    orderItems: [],
    deletedItem: {},
    itemNumber: 0,
    monthlyBilledTotal: "",
    annuallyBilledTotal: "",
    depositBilledTotal: "",
    implementationBilledTotal: "",
    amount: "",
    paymentToken: "",
    expiry: "",
    cardholderName: "",
    billingAddress: "",
    billingCity: "",
    billingState: "",
    billingZipCode: "",
  },
  action
) => {
  switch (action.type) {
    case SELECT_LOCATION_MODE:
      return { ...state, selectedLocationMode: action.payload.selectedLocationMode };
    case SELECT_PAYMODE:
      return { ...state, selectedPayMode: action.payload.selectedPayMode };
    case ADD_TO_ORDER:
      return { ...state, orderItems: action.payload.orderItems };
    case CLEAN_ORDER:
      return { ...state, orderItems: action.payload.orderItems };
    case REMOVE_FROM_ORDER:
      return { ...state, orderItems: action.payload.orderItems, deletedItem: action.payload.deletedItem };
    case UNDELETE_ITEM:
      return { ...state, orderItems: action.payload.orderItems, deletedItem: action.payload.deletedItem };
    case COPY_LOCATION:
      return { ...state, orderItems: action.payload.orderItems };
    case FINALIZEORDER:
      return {
        ...state,
        itemNumber: action.payload.itemNumber,
        monthlyBilledTotal: action.payload.monthlyBilledTotal,
        annuallyBilledTotal: action.payload.annuallyBilledTotal,
        depositBilledTotal: action.payload.depositBilledTotal,
        implementationBilledTotal: action.payload.implementationBilledTotal,
        amount: action.payload.amount,
      };
    case FETCH_QUOTE_ID:
      return { ...state, quoteID: action.payload.quoteID };
    case USEREMVDATA:
      return { ...state, paymentToken: action.payload.paymentToken, expiry: action.payload.expiry };
    case USERPAYMENTINFO:
      return {
        ...state,
        cardholderName: action.payload.cardholderName,
        billingAddress: action.payload.billingAddress,
        billingCity: action.payload.billingCity,
        billingState: action.payload.billingState,
        billingZipCode: action.payload.billingZipCode,
      };
    case ADD_SINGLE_PLAN:
      return { ...state, orderItems: action.payload.orderItems };
    case ADD_MULTIPLE_PLANS:
      return { ...state, orderItems: action.payload.orderItems };
    default:
      return state;
  }
};
