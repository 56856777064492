import React from "react";
import { withStyles, createStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";
import DoneIcon from "@material-ui/icons/Done";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Tooltip from "@material-ui/core/Tooltip";
import Fade from "@material-ui/core/Fade";
import { connect } from "react-redux";
import { add_single_plan, add_multiple_plans, finalizeOrder } from "../../../actions/orderActions";
import { selectPlan, select_multiple_plans } from "../../../actions/planActions";
import { useSnackbar } from "notistack";

const CustomizedTooltip = withStyles({
  tooltip: {
    color: "black",
    backgroundColor: "white",
    fontSize: "0.8em",
  },
})(Tooltip);

const StyledButton = withStyles((theme) => ({
  contained: {
    fontFamily: "avenir-next-lt-pro, sans-serif",
    color: "#ffffff",
    backgroundColor: "#0061d5",
    borderRadius: "4px",
    display: "inline-block",
    border: "none",
    textDecoration: "none",
    fontSize: "1rem",
    transition: "background 100ms ease,border-color 100ms ease",
    lineHeight: "1",
    margin: "0 5px",
    minHeight: "3em",
    "&:hover": {
      opacity: "0.5",
      backgroundColor: "#0979ff",
    },
    "&:disabled": {
      cursor: "not-allowed",
      pointerEvents: "auto",
      color: "#ffffff",
      backgroundColor: "#0061d5",
    },
  },
}))(Button);

const StyledCheckCircleOutlineIcon = withStyles((theme) => ({
  root: {
    color: "#0E8900",
  },
}))(DoneIcon);

const StyledAddCircleOutlineIcon = withStyles((theme) => ({
  root: {
    color: "#0E8900",
    height: "1rem",
    width: "1rem",
  },
}))(AddCircleOutlineIcon);

const StyledRemoveCircleOutlineIcon = withStyles((theme) => ({
  root: {
    color: "#0E8900",
    height: "1rem",
    width: "1rem",
  },
}))(RemoveCircleOutlineIcon);

const StyledHelpOutlinedIcon = withStyles((theme) => ({
  root: {
    height: "1.25rem",
    width: "1.25rem",
    color: "#00b8d1",
  },
}))(HelpOutlineIcon);

const IOSSwitch = withStyles((theme) =>
  createStyles({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      position: "absolute",
    },
    switchBase: {
      padding: 1,
      "&$checked": {
        transform: "translateX(16px)",
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: "#52d869",
          opacity: 1,
          border: "none",
        },
      },
      "&$focusVisible $thumb": {
        color: "#52d869",
        border: "6px solid #fff",
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
  })
)(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

function StepperToggle(props) {
  return (
    <div className="arranger stepper-toggle arranger--column arranger--inline">
      <div className="arranger stepper stepper--small stepper--primary arranger--inline">
        <button
          aria-label="Decrease quantity to 0"
          className="button stepper-bttn stepper-bttn--dec button--small"
          type="button"
          data-tl-id="stepper-toggle-stepper-dec-button"
          data-testid="decrement"
        >
          <span aria-hidden="true" className="elc-icon elc-icon-minus">
            <RemoveIcon onClick={() => props.minOne()} />
          </span>
        </button>
        <div className="stepper-count">
          <div aria-atomic="true" aria-live="polite" aria-relevant="all" className="stepper-count__current">
            <span data-tl-id="stepper-toggle-stepper-value">{props.count}</span>
          </div>
        </div>
        <button
          aria-label="Increase quantity to 2"
          className="button stepper-bttn stepper-bttn--inc button--small"
          type="button"
          data-tl-id="stepper-toggle-stepper-inc-button"
          data-testid="increment"
        >
          <span aria-hidden="true" className="elc-icon elc-icon-add">
            <AddIcon onClick={() => props.plusOne()} />
          </span>
        </button>
      </div>
      <StyledButton variant="contained" className="add_to_cart__button" disabled={props.count == 0} onClick={props.handleSelectMultiple}>
        Add To Cart
      </StyledButton>
    </div>
  );
}

function SubCard2(props) {
  const { plan } = props;
  const { enqueueSnackbar } = useSnackbar(); //, closeSnackbar

  const pricing_features_active = " pricing-featured_features--active";
  const pricing_features_inactive = "pricing-featured_features  pricing-featured_features__has-copy";
  const pricing_featured_features__chart_wrapper_active = " js-toggleView__copy--expanded  js-toggleView__copy--animated-end autoHeight";
  const pricing_featured_features__chart_wrapper_inactive = "pricing-featured_features__chart-wrapper js-toggleView__copy--animatable";

  const [states, setStates] = React.useState({
    count: 0,
    showFeature: false,
    selectedAddons: [],
    selectedSetups: ["online"],
    totalPrice_monthly: plan.billedMonthly,
    totalPrice_annually: plan.billedYearly,
    totalPrice_deposit: plan.deposit ?? 0,
    totalPrice_implementation: plan.implementation ?? 0,
  });

  const minOne = () => {
    if (states.count > 0) {
      setStates({ ...states, count: states.count - 1 });
    }
  };
  const plusOne = () => {
    setStates({ ...states, count: plan.isSingleton && states.count === 1 ? states.count : states.count + 1 });
  };

  const handleFeatureTriggeredButton = (event) => {
    setStates({ ...states, showFeature: !states.showFeature });
  };

  const handleSelect = () => {
    const { totalPrice_monthly, selectedAddons, selectedSetups, totalPrice_annually, totalPrice_deposit, totalPrice_implementation } = states;
    props.selectPlan(plan, selectedAddons, selectedSetups, totalPrice_monthly, totalPrice_annually, totalPrice_deposit, totalPrice_implementation);
    props.add_single_plan(plan, selectedAddons, selectedSetups, totalPrice_monthly, totalPrice_annually, totalPrice_deposit, totalPrice_implementation);
    props.finalizeOrder();
    props.history.push({ pathname: "/checkout", state: { from: props.location } });
  };

  const handleAddon = (value) => {
    const { selectedAddons, selectedSetups, totalPrice_annually, totalPrice_monthly, totalPrice_deposit, totalPrice_implementation } = states;
    const multipler = selectedAddons.includes(value) ? -1 : 1;
    const updated_total_price_monthly = totalPrice_monthly + multipler * plan.addons.filter((x) => x.key === value)[0].billedMonthly;
    const updated_total_price_annually = totalPrice_annually + multipler * plan.addons.filter((x) => x.key === value)[0].billedYearly;
    const updated_total_price_deposit = totalPrice_deposit + multipler * plan.addons.filter((x) => x.key === value)[0].deposit;
    let updated_total_price_implementation = totalPrice_implementation;
    let updated_Setups = selectedSetups;
    if (multipler < 0 && selectedSetups.includes(value)) {
      updated_total_price_implementation = totalPrice_implementation + multipler * plan.addons.filter((x) => x.key === value)[0].implementation;
      updated_Setups = selectedSetups.filter((c) => c !== value);
    }
    setStates({
      ...states,
      selectedAddons: selectedAddons.includes(value) ? selectedAddons.filter((c) => c !== value) : [...selectedAddons, value],
      selectedSetups: updated_Setups,
      totalPrice_annually: updated_total_price_annually,
      totalPrice_monthly: updated_total_price_monthly,
      totalPrice_deposit: updated_total_price_deposit,
      totalPrice_implementation: updated_total_price_implementation,
    });
  };

  //If the plan.implementation = 0, the user can select whether to have the online setup or not
  //If the plan.implementation > 0, the user can not remove the 'online' from selectedSetups
  const handleSetups = (value) => {
    const { selectedSetups, totalPrice_implementation } = states;
    const multipler = selectedSetups.includes(value) ? -1 : 1;
    let updated_total_price_implementation = totalPrice_implementation;
    let updatedSetups = selectedSetups;

    //setup items(except online) can modify the toal impementation charge.
    if (value !== "online") {
      updated_total_price_implementation = totalPrice_implementation + multipler * plan.addons.filter((x) => x.key === value)[0].implementation;
      updatedSetups = updatedSetups.includes(value) ? updatedSetups.filter((c) => c !== value) : [...updatedSetups, value];
    } else {
      if (plan.implementation === 0) {
        updatedSetups = updatedSetups.includes(value) ? updatedSetups.filter((c) => c !== value) : [...updatedSetups, value];
      }
    }

    setStates({
      ...states,
      selectedSetups: updatedSetups,
      totalPrice_implementation: updated_total_price_implementation,
    });
  };

  const handleSelectMultiple = () => {
    if (plan.isSingleton && props.orderItems.map((x) => x.key).includes(plan.key)) {
      //snackbar settings
      const message = "Already have this item in the cart.";
      enqueueSnackbar(message, {
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        variant: "warning",
        autoHideDuration: 4000,
      });
    } else {
      const { count, totalPrice_monthly, selectedAddons, selectedSetups, totalPrice_annually, totalPrice_deposit, totalPrice_implementation } = states;
      props.add_multiple_plans(
        count,
        plan,
        selectedAddons,
        selectedSetups,
        totalPrice_monthly,
        totalPrice_annually,
        totalPrice_deposit,
        totalPrice_implementation
      );
      props.select_multiple_plans(count, plan); //Copy the complete info for added plans
      props.finalizeOrder();
      setStates({ ...states, count: 0 });

      //snackbar settings
      const message = `${states.count} item${states.count > 1 ? "s are" : " is"} added into the cart.`;
      const action = (key) => <Button onClick={() => props.history.push("/orderSummary")}>View cart</Button>;
      enqueueSnackbar(message, {
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        variant: "success",
        autoHideDuration: 3000,
        action,
      });
    }
    // props.history.push({ pathname: "/orderSummary", state: { from: props.location } });
  };

  return (
    <div role="article" className="pricing-package">
      {plan.isPopular === true ? (
        <div class="pricing-package--recommended__wrapper">
          <span class="pricing-package--recommended__label">Most popular</span>
        </div>
      ) : null}
      <div className="pricing-package--heading">
        <h3>{plan.name}</h3>
      </div>
      <div className={props.pricingToggleIsChecked ? "pricing-package--price" : "pricing-package--price--monthly pricing-package--price"}>
        <b className={props.pricingToggleIsChecked ? "" : "pricing-package--price--monthly"}>
          <span className="monthly-price">${states.totalPrice_monthly}</span>{" "}
          <span className="annual-price">${Math.ceil(states.totalPrice_annually / 12)}</span>
        </b>
        {/* <p>
          per location/month <span className="annual-price-info">paid annually</span>
        </p> */}
        <p className="deposit-price">+ Deposit: ${states.totalPrice_deposit}</p>
        <p className="implementation-price">+ Implementation: ${states.totalPrice_implementation}</p>
      </div>

      <div className="pricing-package__buttons-wrapper">
        {props.plansToggleIsChecked ? (
          <div className="pricing-package__buttons">
            <StyledButton variant="contained" className="button-primary" onClick={() => handleSelect()}>
              Select
            </StyledButton>
          </div>
        ) : (
          <StepperToggle minOne={minOne} plusOne={plusOne} count={states.count} handleSelectMultiple={handleSelectMultiple} />
        )}
      </div>

      <div className="pricing-package-description">
        <p>{plan.description}</p>
      </div>

      <div className={states.showFeature ? pricing_features_inactive + pricing_features_active : pricing_features_inactive}>
        <div
          style={states.showFeature ? { height: "auto" } : null}
          className={
            states.showFeature
              ? pricing_featured_features__chart_wrapper_inactive + pricing_featured_features__chart_wrapper_active
              : pricing_featured_features__chart_wrapper_inactive
          }
        >
          <dl className="pricing-featured_features__chart">
            <dd className="available">
              <StyledCheckCircleOutlineIcon style={{ margin: "0 10px -5px 0", overflow: "hidden" }} />
              <p style={{ display: "inline-block", margin: "0" }}>US-Based Support</p>
            </dd>
            <dd className="available">
              <StyledCheckCircleOutlineIcon style={{ margin: "0 10px -5px 0", overflow: "hidden" }} />
              <p style={{ display: "inline-block", margin: "0" }}>24/7 Access</p>
            </dd>

            {plan.includes.map((availableItem) => (
              <dd className="available">
                <StyledCheckCircleOutlineIcon style={{ margin: "0 10px -5px 0", overflow: "hidden" }} />
                <p style={{ display: "inline-block", margin: "0" }}>{availableItem.name}</p>
                <CustomizedTooltip
                  style={{ display: "inline-block", position: "absolute" }}
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: 600 }}
                  title={availableItem.description}
                  enterTouchDelay={0}
                  arrow
                >
                  <StyledHelpOutlinedIcon style={{ margin: "2px 0 0 8px" }} />
                </CustomizedTooltip>
              </dd>
            ))}

            {/* {plan.cardContentUnavailable && plan.cardContentUnavailable.map((unavailableItem) => <dd className="unavailable">{unavailableItem.name}</dd>)} */}
          </dl>
        </div>
        <Button className="pricing-features__button" onClick={() => handleFeatureTriggeredButton()}>
          <span className="pricing-features__button--show">
            Show features
            <StyledAddCircleOutlineIcon className="icon-circle_plus" />
          </span>
          <span style={{ display: "none" }} aria-hidden="true" className="pricing-features__button--hide">
            Hide features
            <StyledRemoveCircleOutlineIcon className="icon-circle_minus" />
          </span>
        </Button>
      </div>
      <div className="pricing-package-addons">
        <div className="section_bar">Add Add-ons</div>
        {plan.addons.map((addon) => (
          <div key={addon.key} className="addon_item_wrapper">
            <p className="addon_title">{addon.name}</p>
            <p className="addon_text">Add ${addon.billedMonthly} /month</p>
            {addon.deposit > 0 && <p className="addon_text">+ Deposit: ${addon.deposit} one-time</p>}
            <IOSSwitch className="add-ons-toggle" checked={states.selectedAddons.includes(addon.key)} onChange={() => handleAddon(addon.key)} />
          </div>
        ))}
      </div>

      <div className="pricing-package-setups">
        <div className="section_bar">Add Set-ups</div>
        <div className="addon_item_wrapper">
          <p className="addon_title">Online</p>
          <p className="addon_text">{plan.implementation > 0 ? `Add $${plan.implementation} one-time` : "Free"}</p>
          <IOSSwitch
            className="add-ons-toggle"
            checked={plan.implementation > 0 ? true : states.selectedSetups.includes("online")}
            onChange={() => handleSetups("online")}
          />
        </div>
        {plan.addons
          .filter((x) => states.selectedAddons.includes(x.key))
          .map((addon) => (
            <div key={addon.key} className="addon_item_wrapper">
              <p className="addon_title">{addon.name}</p>
              <p className="addon_text">{addon.implementation > 0 ? `Add $${addon.implementation} one-time` : "Free"}</p>
              <IOSSwitch className="add-ons-toggle" checked={states.selectedSetups.includes(addon.key)} onChange={() => handleSetups(addon.key)} />
            </div>
          ))}
      </div>
    </div>
  );
}

export default connect(
  (state) => ({
    selectedPayMode: state.order.selectedPayMode,
    orderItems: state.order.orderItems,
  }),
  {
    selectPlan,
    add_single_plan,
    add_multiple_plans,
    select_multiple_plans,
    finalizeOrder,
  }
)(SubCard2);
