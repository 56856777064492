import { CREATE_USERS1, CREATE_USERS2, SEND_CAPTCHA, LOGIN, LOGOUT, FAILED_LOGIN, REGISTER, GET_USER_INFO } from "../types";
import { APIRequest } from "../utils";
import Auth from "../modules/Auth";

//The newUser will be the data passed by redux-form onSubmit
export const createUser1 = (newUser) => (dispatch) => {
  dispatch({
    type: CREATE_USERS1,
    payload: newUser,
  });
  // localStorage.setItem("currentUser", JSON.stringify(newUser));
};

export const createUser2 = (passwordVal) => (dispatch, getState) => {
  const currentUser = getState().user.currentUser;
  const updatedCurrentUser = { ...currentUser, password: passwordVal };

  dispatch({
    type: CREATE_USERS2,
    payload: { currentUser: updatedCurrentUser },
  });
  // localStorage.setItem("existingUsers", JSON.stringify(existingUsers));
  // localStorage.setItem("currentUser", JSON.stringify(updatedCurrentUser));
};

export const sendCaptcha = () => (dispatch, getState) => {
  const email = getState().user.currentUser.email;
  const SERVER_URL = process.env.REACT_APP_SERVER_URL ?? "http://localhost:4000";
  const bodyContent = {
    passport: email,
    passportType: "email",
    captcha: "",
    captchaType: "register",
    nickname: "",
  };
  return APIRequest({
    url: `${SERVER_URL}/proxyAuth/captcha/sendCaptcha`,
    method: "POST",
    body: bodyContent,
  })
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      if (data.error) {
        console.log("You have reach the daily limitation of requesting the verification code.");
      } else {
        dispatch({
          type: SEND_CAPTCHA,
          payload: null,
        });
      }
    });
};

export const verifyCaptcha = (value) => (dispatch, getState) => {
  const email = getState().user.currentUser.email;
  const bodyContent = {
    passport: email,
    passportType: "email",
    captcha: value,
    captchaType: "register",
    nickname: "",
  };
  const SERVER_URL = process.env.REACT_APP_SERVER_URL ?? "http://localhost:4000";
  return APIRequest({
    url: `${SERVER_URL}/proxyAuth/captcha/verifyCaptcha`,
    method: "POST",
    body: bodyContent,
  })
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      if (data.result) {
        return data.result.passportToken;
      }
    })
    .catch((error) => {
      alert(error.message);
    });
};

export const register = (value) => (dispatch, getState) => {
  const userInfo = getState().user.currentUser;
  const bodyContent = {
    pwd: userInfo.password,
    passportToken: value,
    passportUserName: `${userInfo.firstName} ${userInfo.lastName}`,
    nickname: userInfo.firstName,
    extra: {
      email: userInfo.email,
      lastName: userInfo.lastName,
      firstName: userInfo.firstName,
      brand: userInfo.restaurantName,
    },
  };
  const SERVER_URL = process.env.REACT_APP_SERVER_URL ?? "http://localhost:4000";
  return APIRequest({
    url: `${SERVER_URL}/proxyAuth/passport/register`,
    method: "POST",
    body: bodyContent,
  })
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      if (data.result) {
        dispatch({
          type: REGISTER,
          payload: {
            isLogin: true,
            passportToken: value,
          },
        });
        console.log("Registered successfully!");
        localStorage.setItem("accessToken", data.result.accessToken);
        localStorage.setItem("refreshToken", data.result.refreshToken);
        localStorage.setItem("email", userInfo.email);
      } else if (data.error) {
        console.log(`Registered Failed----${data.error.message}`);
      }
    });
};

export const login = (email, password) => (dispatch) => {
  const bodyContent = {
    passport: email,
    pwd: password,
    passportType: "email",
  };

  const SERVER_URL = process.env.REACT_APP_SERVER_URL ?? "http://localhost:4000";
  return APIRequest({
    url: `${SERVER_URL}/proxyAuth/passport/login`,
    method: "POST",
    body: bodyContent,
  })
    .then((res) => {
      return res.json();
    })
    .then(async (data) => {
      console.log("Login successfully!");
      dispatch({ type: LOGIN, payload: true });
      localStorage.setItem("accessToken", data.result.accessToken);
      localStorage.setItem("refreshToken", data.result.refreshToken);
      await APIRequest({
        url: `${SERVER_URL}/proxyAuth/user/getInfo`,
        method: "POST",
        token: localStorage.getItem("accessToken"),
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          dispatch({
            type: GET_USER_INFO,
            payload: {
              currentUser: data.result,
            },
          });
          localStorage.setItem("email", data.result.email);
          console.log("successfully fetched user's info");
          return true;
        })
        .catch((err) => {
          console.log(err);
          return false;
        });
      return true;
    })
    .catch((error) => {
      dispatch({ type: FAILED_LOGIN, payload: true });
      console.log(`Login Failed----${error.message}`);
      return false;
    });
};

// export const getUserInfo = () => async (dispatch) => {
//   const SERVER_URL = process.env.REACT_APP_SERVER_URL ?? "http://localhost:4000";
//   const res = await APIRequest({
//     url: `${SERVER_URL}/proxyAuth/user/getInfo`,
//     method: "POST",
//     token: localStorage.getItem("accessToken"),
//   });
//   const data = await res.json();
//   if (data.result) {
//     dispatch({
//       type: GET_USER_INFO,
//       payload: {
//         currentUser: data.result,
//       },
//     });
//   } else {
//     console.error(data.error);
//   }
// };

export const logout = () => (dispatch) => {
  Auth.signout();
  dispatch({
    type: LOGOUT,
    payload: false,
  });
};
