import React from "react";
import { Button, Box, Typography, Container } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import Fade from "react-reveal/Fade";
import { connect } from "react-redux";
import { login } from "../../actions/userActions";
import { APIRequest } from "../../utils";

const StyledButton = withStyles((theme) => ({
  contained: {
    fontFamily: "avenir-next-lt-pro, sans-serif",
    color: "#ffffff",
    backgroundColor: "#0061d5",
    borderRadius: "4px",
    display: "inline-block",
    border: "none",
    textDecoration: "none",
    fontSize: "1rem",
    transition: "background 100ms ease,border-color 100ms ease",
    lineHeight: "1",
    minHeight: "3em",
    "&:hover": {
      opacity: "0.5",
      backgroundColor: "#0979ff",
    },
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  formTitle: {
    justify: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  link: {
    "&:hover": {
      color: "red",
      cursor: "context-menu",
    },
    fontWeight: 600,
    textDecoration: "underline",
  },
  button: {
    root: {
      "&hover": {
        opacity: 0.3,
        backgroundColor: "#ef3e3d",
      },
    },
  },
}));

function Header() {
  return (
    <Typography component="h1" variant="h4">
      Forget Password ?
    </Typography>
  );
}

function ForgetPwd(props) {
  const classes = useStyles();
  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .required("Required")
      .email("Invalid Email")
      .test("doesExist", "Email does not exist", async (value) => {
        const bodyContent = {
          passport: value,
          passportType: "email",
        };
        const res = await APIRequest({
          url: `${process.env.REACT_APP_SERVER_URL}/proxyAuth/passport/hasRegister`,
          method: "POST",
          body: bodyContent,
        });
        const data = await res.json();
        return data.result.hasRegister;
      }),
  });

  return (
    <Container maxWidth="xs" className={classes.paper}>
      <Header className={classes.formTitle} />
      <Formik
        initialValues={{ email: "" }}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={async (values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
          }, 500);
          //   props.signin(values);
        }}
        validationSchema={validationSchema}
      >
        {({ submitForm, isSubmitting, touched, errors }) => (
          <Form className={classes.form}>
            <Fade bottom cascade>
              <div>
                <Box margin={2}>
                  <Field component={TextField} variant="outlined" name="email" type="text" label="Email" autoFocus fullWidth />
                </Box>
                {/* {isSubmitting && <LinearProgress />} */}

                <Box margin={2}>
                  <StyledButton variant="contained" color="primary" disabled={isSubmitting} onClick={submitForm} fullWidth>
                    Request Reset Link
                  </StyledButton>
                </Box>
              </div>
            </Fade>
          </Form>
        )}
      </Formik>
    </Container>
  );
}

export default connect((state) => ({}), {
  login,
})(ForgetPwd);
