import { CREATE_USERS1, CREATE_USERS2, SEND_CAPTCHA, FAILED_LOGIN, LOGIN, REGISTER, LOGOUT, GET_USER_INFO } from "../types"; //VERIFY_CAPTCHA,

export const userReducer = (
  state = {
    currentUser: {
      firstName: null,
      lastName: null,
      email: null,
      RestaurantName: null,
    },
    passportToken: "",
    failedLogin: false,
    isLogin: localStorage.getItem("accessToken") !== null,
  },
  action
) => {
  switch (action.type) {
    case CREATE_USERS1:
      return { ...state, currentUser: action.payload };
    case CREATE_USERS2:
      return { ...state, currentUser: action.payload.currentUser };
    case SEND_CAPTCHA:
      return { ...state };
    // case VERIFY_CAPTCHA:
    //   return { ...state, passportToken: action.payload.passportToken };
    case FAILED_LOGIN:
      return { ...state, failedLogin: true, isLogin: false };
    case LOGIN:
      return { ...state, isLogin: true };
    case REGISTER:
      return { ...state, isLogin: action.payload.isLogin, passportToken: action.payload.passportToken };
    case LOGOUT:
      return { ...state, isLogin: false };
    case GET_USER_INFO:
      return { ...state, currentUser: action.payload.currentUser };
    default:
      return state;
  }
};
