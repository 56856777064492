import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import SignupForm from "./components/signup/createAccountForm";
import EmailVerifyForm from "./components/signup/emailVerifyForm";
import QuotePage from "./pages/QuotePage";
import CheckoutPage from "./pages/CheckoutPage";
import AskQuote from "./components/ALTBuildPackage/quote/askQuote";
import ForgetPwd from "./components/login/forgetPwd";
import ConcisePricing from "./components/ALTBuildPackage/buildPackage/concisePricing";
import Layout from "./components/layout/layout";
import LogIn from "./components/login/login";
import OrderConfirmation from "./components/ALTBuildPackage/buildPackage/orderConfirmation";
import Auth from "./modules/Auth";
import { Provider } from "react-redux";
import store from "./store";
import OrderSummary from "./components/ALTBuildPackage/buildPackage/orderSummary";

export default function App() {
  return (
    <Provider store={store}>
      <Router>
        <Layout>
          <Switch>
            <Route exact path="/" render={(props) => <ConcisePricing {...props} />} />
            <Route path="/orderSummary" component={OrderSummary} />
            <PrivateRoute path="/checkout" component={CheckoutPage} />
            <PrivateRoute path="/orderConfirmation" component={OrderConfirmation} />

            <Route path="/buildPackage" render={(props) => <AskQuote {...props} />} />
            <Route path="/quote" render={(props) => <QuotePage {...props} />} />

            <Route path="/login" render={(props) => <LogIn {...props} />} />
            <Route path="/signup" render={(props) => <SignupForm {...props} />} />
            <Route path="/emailVerify" render={(props) => <EmailVerifyForm {...props} />} />
            <Route path="/forgotPassword" render={(props) => <ForgetPwd {...props} />} />
          </Switch>
        </Layout>
      </Router>
    </Provider>
  );
}

function PrivateRoute({ component: Component, ...props }) {
  return (
    <Route
      {...props}
      render={(props) => {
        sessionStorage.removeItem("quoteID");
        const urlParams = new URLSearchParams(window.location.search);
        const quoteID = urlParams.get("q");
        if (quoteID && quoteID.length >= 1) {
          sessionStorage.setItem("quoteID", quoteID);
          console.log("quoteID: ", quoteID);
        }

        return Auth.isUserAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/signup",
              state: { from: props.location, prevPath: props.location.state?.from },
            }}
          />
        );
      }}
    />
  );
}
