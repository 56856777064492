import axios from "axios";

export default function arrayEquals(a, b) {
  return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => b.indexOf[val] >= 0);
}

export const getSafe = (fn, defaultValue) => {
  try {
    return fn();
  } catch (e) {
    return defaultValue;
  }
};

export const callGraphQlApi = (query, variables) => {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL ?? "http://localhost:4000";
  return axios.post(`${SERVER_URL}/admin/api`, {
    query,
    variables: JSON.stringify(variables),
  });
};

export const APIRequest = (param) => {
  return fetch(param.url, {
    method: param.method || "POST",
    body: JSON.stringify(param.body) || null,
    headers: {
      "Content-Type": "application/json",
      token: param.token || null,
    },
  });
};

export const formatMonetaryAmount = (amount, props, states) => {
  if (props?.isLoading && states?.isLoading) return "...";
  if (typeof amount === "string") amount = Number.parseFloat(amount);
  else if (typeof amount === "undefined" || amount === null) amount = 0;
  return amount.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};
