import React from "react";
import { Container } from "@material-ui/core";

function PricingHero(props) {
  return (
    <div className="pricing-hero">
      {/* <div className="container"> */}
      <header className="pricing-hero--header">
        <h1 id="pricing-heading">Choose the best plan for your business</h1>
      </header>
      {/* </div> */}
    </div>
  );
}

export default function PricingPageWrapper(props) {
  return (
    <>
      <PricingHero selectedTab={props.selectedTab} />
      <Container component="main" maxWidth="lg">
        {props.children}
      </Container>
    </>
  );
}
